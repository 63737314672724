import React from 'react';
import './topbar.css';

import { NavLink, useNavigate } from 'react-router-dom';
import { NotificationsNone, Settings } from '@mui/icons-material';
import { Button, Menu, MenuItem } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Person, Key, Logout } from '@mui/icons-material';

export default function Topbar() {

    const pic = sessionStorage.getItem('profile_pic');
    console.log(pic , "I am the pictureeeeee")
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const logoutClick = (e) => {
        e.preventDefault();
        toast.success('Logout Successful !', {
            position: toast.POSITION.TOP_RIGHT
        });
        localStorage.clear('token');
        sessionStorage.clear('token');
        navigate('/')

        // setTimeout(window.location.reload.bind(window.location), 10);
        window.location.reload(true);    
    }
    const profile_pic = sessionStorage.getItem('profile_pic')
    return (
        <div className="topbarWrapper">

            <div className="logoContainer">
                <NavLink to='/' className='link'>
                    <img src="/images/logo.png" alt="logo" className='logoImg'></img>
                    <span className="spanName">Tapp App</span>
                </NavLink>
            </div>
            <div className="topRight">
                {/* <div className="topbarIconContainer">
                    <NotificationsNone sx={{ color: '#2f323b' }} />
                    <span className="topIconbadge">2</span>
                </div> */}
                {/* <div className="topbarIconContainer">
                    <Settings sx={{ color: '#2f323b' }} />
                </div> */}

                <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    sx={{ shadow: 'none' }}
                >
                    <img src={pic ? pic : `https://admin.thetapplist.com/uploads/blank_pic.png`} alt="" className='topAvatar' />

                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <NavLink to='/profile' className='link'>
                        <div>
                            <MenuItem onClick={handleClose}><Person className='menuIcons' />Profile</MenuItem>
                        </div>
                    </NavLink>
                    <NavLink to='/change_Password' className='link'>
                        <div>
                            <MenuItem onClick={handleClose}><Key className='menuIcons' />Change Password</MenuItem>
                        </div>
                    </NavLink>
                    <MenuItem onClick={logoutClick}><Logout className='menuIcons' />Logout</MenuItem>
                    <ToastContainer />
                </Menu>
            </div>
        </div>
    )
}
