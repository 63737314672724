import * as React from "react";
import { lazy } from "react";
import "./app.css";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import Routing from "./Routing.js";
import "react-phone-input-2/lib/style.css";
import { useLocation } from "react-router-dom";

// my imports
import Sidebar from "./components/sidebar/Sidebar";
import Topbar from "./components/topbar/Topbar";
import Login from "./pages/login/Login.jsx";
import { useEffect } from "react";
import axios from "axios";
import server from "./server";
import { toast } from "react-toastify";
import io from 'socket.io-client';

const socket= io(server.live_image,{secure: true})

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  background: "#d7d7d7",
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",

  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

//      AUTHENTICATION       //

const token = sessionStorage.getItem("token");
var loggedIn;
token ? (loggedIn = true) : (loggedIn = false);
console.log(token, "Token");

////////////////////////////////////

export default function App() {
  const [state, setState] = React.useState("");

  // useEffect (()=>{
  //  axios.get(server.dev+"/dashboard").then((res)=>{
  //   if(res.data.code===203){
  //     if(loggedIn===true){
  //       loggedIn=false
  //       // toast.error("Session expired please login again")
  //     }

  //   }
  //  })

  // })

  const router = useLocation();

  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleResize = () => {
    if (window.innerWidth < 1020) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  useEffect(() => {
    // axios.get("")
  });

  useEffect(() => {
    socket.on("connect", () => {
      console.log(socket.connected,"the connection"); // true
    });
  })

  return (
    <>
      {loggedIn ?   ( 
        <Box
          sx={{
            display: "flex",
            height: "100%",
          }}
        >
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          >
            <Toolbar className="app_bar_tap">
              {open === false ? (
                <IconButton
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{
                    mr: 2,
                    color: "#2f323b",
                    ...(open && { display: "none" }),
                  }}
                >
                  <MenuIcon />
                </IconButton>
              ) : (
                <IconButton
                  onClick={handleDrawerClose}
                  edge="start"
                  sx={{ mr: 2, color: "#2f323b" }}
                >
                  <CloseIcon />
                </IconButton>
              )}
              <Topbar />
            </Toolbar>
          </AppBar>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            {/* <DrawerHeader>
              
            </DrawerHeader> */}
            <Divider />
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon sx={{ position: "left", color: "#2f323b" }} />
              {/* {theme.direction === 'ltr' ? <CloseIcon sx={{position: 'left'}} /> : <ChevronRightIcon />} */}
            </IconButton>
            <Sidebar />
          </Drawer>

          <Main open={open}>
            <DrawerHeader className="appbarDown" />

            <Routing />
          </Main>
        </Box>
      ) : (
        <Login />
      )}
    </>
  );
}
