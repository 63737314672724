import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
  {
    name: 'Aug',
    Buyers: 0,
    Sellers: 1,
    amt: 2400,
  },
  {
    name: 'Sep',
    Buyers: 3,
    Sellers: 5,
    amt: 2210,
  },
  {
    name: 'Oct',
    Buyers: 1,
    Sellers: 3,
    amt: 2290,
  },
  {
    name: 'Nov',
    Buyers: 2,
    Sellers: 2,
    amt: 2000,
  }, 
  {
    name: 'Dec',
    Buyers: 1,
    Sellers: 4,
    amt: 2181,
  }
];

export default function Chart() {

    return (
      <div style={{ width: '100%', height: 350 , marginTop: '20px'}}>
      <ResponsiveContainer >
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="Buyers" stroke="#8884d8" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="Sellers" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>
      </div>
    );
  
}
