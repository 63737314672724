import * as React  from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';

import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import TransitionsModal from '../../components/modal/Modal'




export default function TextMobileStepper({licImg}) {
console.log(licImg, "licimgssss")
if(!licImg){
  licImg = [ '/images/noImage.jpg' , '/images/noImage.jpg']
 
}

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = licImg.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ maxWidth: 400, flexGrow: 1, marginTop: 2 }}>
     
     

      <Box  component="img" onClick={()=> window.open(licImg[activeStep])} sx={{ height: 252, width: '380px', p: 2, padding: '2px', marginTop: '8px', boxShadow: 'rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px', borderRadius: '26px', objectFit: 'cover' }}  src={licImg[activeStep]}
                alt='' />
       
      <MobileStepper
        variant="text"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button                                                       
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            
          </Button>
        }
      />
    </Box>
  );
}






