import './sellerDetail.css';
import Toptag from '../../components/topTag/Toptag';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect,useRef, useState } from 'react';
import axios from 'axios';

import Tooltip from '@mui/material/Tooltip';

import { ToastContainer, toast } from 'react-toastify';

import Loader from '../../components/loader/Loader'
import server from '../../server';
import PhoneInput , {CountryData} from 'react-phone-input-2'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Grid } from '@mui/material';


export default function SellerDetail() {
  const redirect= useNavigate()
  const location = useLocation();
  const { id } = location.state;
  console.log(id, "id coming here")
  const [edit, setEdit] = useState('disabled');
  const [edit1, setEdit1] = useState(false);
  const [one, two] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [countryCode,setCountryCode]=useState("")
  const [mobile,setMobile]=useState("")
  const inputFile = useRef(null);
  const [localpic,setLocalpic]=useState("")
  const [updatedPic,setPic]= useState("")
  const [pIm,setPim] =useState("")
  // const [c_name,setCname] = useState("")

  const editFunction = () => {
    setEdit('')
    setEdit1(true)
    window.scrollTo({   top: document.documentElement.scrollHeight, behavior: 'smooth' }); 
  }

  const hide =()=>{
    setEdit('disabled')
    setEdit1(false)
    window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
    setLocalpic(pIm)
  }

  const handleKeyDown = e => {
    console.log(e.target.value)
    if (e.target.value === '') {
      if (e.key === " ") {
        e.preventDefault();
      }
    }
  };

  const inputEvent = (e) => {
    const { id, value } = e.target;
    // alert("coming")
    two((prevalue) => {
      return {
        ...prevalue,
        [id]: value
      };
    });
  };

  const updateData = () => {
    console.log('updated')
  }

  const updateUserdata = (e) => {
    // alert("here")
    e.preventDefault();

    let obj = { full_name: one.full_name, _id: one._id,profile_photo: updatedPic,countryCode:countryCode ==="" ? "": "+" + countryCode,contact:mobile}
    
    const formdata= new FormData()
    formdata.append("full_name",obj.full_name)
    formdata.append('file',obj.profile_photo)
    formdata.append('countryCode',obj.countryCode)
    formdata.append('contact',obj.contact)
    formdata.append('userId',obj._id)
    axios.post(server.live_dev+"/update_user",formdata).then((res)=>{
      if(res.data.code===203){
        toast.error(res.data.message)
        sessionStorage.clear()
        setTimeout(()=> window.location.reload(true),1000)
      }else if(res.data.code===201){
        toast.error(res.data.message)
      }else{
        toast.success(res.data.message)
        getData()
      }
    })
      // alert(obj)
    console.log(formdata,"blah blah")
   
  }

  const getData = async () => {
    const ff = await axios.get(server.live_dev+'/user_details', { headers:{"token":sessionStorage.getItem("token")} ,params: { userId: id } });
    
    if(ff.data.code===203){
      toast.error(ff.data.message)
      sessionStorage.clear()
      setTimeout(()=> window.location.reload(true),1000)
    }else if(ff.data.code===201){
      toast.error(ff.data.message)
    }else{
      console.log(ff, "thi is ff coming ")
      two(ff.data.results)
      var photo=ff.data.results.profile_photo
      if(photo===""){
        setLocalpic(server.user_static)
        setPim(server.user_static)
      }else{
        setLocalpic(server.live_image+ff.data.results.profile_photo)
        setPim(server.live_image+ff.data.results.profile_photo)
      }
      if (ff) { setIsLoading(false); }
    }
    

  }

  useEffect(() => {
    setIsLoading(true);
    getData();
  }, [])

  // alert(`${one.profile_photo}`)
  const setphoneuse=async(data,country)=>{
    // console.log(data.slice(country.dialCode.length),"fffff",country)
    setCountryCode(country.dialCode)
    setMobile(data.slice(country.dialCode.length))
  }

  const onButtonClick = () => {
    console.log(inputFile, "file");
    console.log(typeof edit1, "down edit1");
    console.log(edit1 === "disabled", "down edit1");
    inputFile.current.click();
  };
  const handleChange = (e) => {
    console.log(e.target.files[0], "threegggggggggggggggggggggggggg");
    if (e.target.files.length > 0) {
      let filename = e.target.files[0];
      setLocalpic(URL.createObjectURL(filename))
      setPic(filename)
      setPim(localpic)
      // four({
      //   localPic: URL.createObjectURL(filename),
      //   pic: filename.name,
      //   file: filename,
      // });
    }
  };

  const update_user_data = () =>{
    
  }
  
  return (

    <>
      {isLoading
        ?
        <Loader />
        :
        <div className='container-fluid'>

          <ToastContainer />
          <Toptag />
          {console.log(one,"ddddddd")}


          <div className="userDetCont">

            <div className="userTitleContainer">
              <span className="detailTitle">User Details</span>
              <button className='userAddButton' hidden={edit1} onClick={editFunction} >Edit</button>
            </div>

            <div className="mainBoxx">
              <div className="imGCont">
                  <img src={localpic} onClick={() =>window.open(localpic)} className='myImg' alt="" />           
              </div>
              {
                  edit1 === true ? 
                  <>
                  <div className="image-upload">
                <label for="image">
                <Tooltip title="Change your profile pic"><AddPhotoAlternateIcon  style={{fontSize:"30px",color:"grey",cursor:"pointer"}}/></Tooltip>
                </label>

                <input id="image"  type="file" onChange={handleChange}/>
                </div>
                  </> : <></> 
                }
              <div className="formContainer">
                <div className="formInMain">

                <label className="myLabel">Name</label>
                  <input
                    type="text"
                    disabled={edit}
                    // onKeyDown={handleKeyDown}
                    id="full_name"
                    value={one.full_name}
                    onChange={inputEvent}
                    className="myInput"
                    placeholder={one.full_name}
                  />
                  {/* <label className='myLabel'>Username</label>
                  <input type="text" disabled={edit} onKeyDown={handleKeyDown} onChange={inputEvent} id='user_name' value={one.user_name} className="myInput" /> */}

                  <label className='myLabel'>Email</label>
                  <input type="text" readOnly disabled value={one.email} className="myInput" />

                  <label className='myLabel'>Contact</label>
                  <PhoneInput
                  country={CountryData}
                  enableSearch={true}  
                              
                  // placeholder={user.countryCode+user.contact}
                  value={one.countryCode + one.contact}
                  disabled={edit}
                  onChange={(data,country) => setphoneuse(data,country)}
                  />
                  {/* <input type="number" disabled={edit} onKeyDown={handleKeyDown} onChange={inputEvent} id='contact' value={one.contact} className="myInput" /> */}

                </div>
              </div>
            </div>
            <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
              <Grid item>
              <Tooltip title="Click to Update profile" >
                      <button hidden={edit} className='updateDataBtn' onClick={updateUserdata} >
                        Update
                      </button>
                    </Tooltip>
              </Grid>
              <Grid item>
              <Tooltip title="Cancel" >
                      <button hidden={edit} className='updateDataBtn' onClick={hide} >
                        Cancel
                      </button>
                    </Tooltip>
              </Grid>

            </Grid>
                 
          </div>
        </div>
      }
    </>



  )
}
