import React from "react";
import { Link, Outlet ,Navigate, NavLink} from "react-router-dom";

function ProtectedRoute() {
  var isAuthenticated = sessionStorage.getItem("token");
  
  return (
    isAuthenticated ? <Outlet/>:<NavLink to="/"/>
  );
}

export default ProtectedRoute;