import './chartdata.css';
import StackedChart from '../../components/chart/Chart'
import Chart from '../../components/chart/Chart';
import Stacked from '../../components/chart/Stacked';
import BarChart from '../../components/chart/BarChart';
import PieChart from '../../components/chart/Piechart';
import { ToastContainer } from 'react-toastify';
import Toptag from '../../components/topTag/Toptag';

export default function ChartData() {
    return (
        <div className='container'>

        <ToastContainer />
        <Toptag />
        <div className="chartDataCont">
            <div className="boxes">
                <Chart/>
                <div className="spanCo">
                <span>USER DETAILS</span>
                </div>
            </div>
            <div className="boxes">
             <PieChart />
             <div className="spanCo">
                <span>USER DETAILS</span>
                </div>
            </div>
            <div className="boxes">
             <BarChart/>
             <div className="spanCo">
                <span>ORDER DETAILS</span>
                </div>
            </div>
            <div className="boxes">
            <Stacked/>
             <div className="spanCo">
                <span>SHOPS DETAILS</span>
                </div>
            </div>
        </div>
        </div>
    )
}
