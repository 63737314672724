import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';
import axios from 'axios';
import server from '../../server';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import {socket} from '../../socket/socket' 

function Closegroup({ isDeleteOpened,onClick, CloseDialog ,body }) {
    const history =useNavigate()
    // console.log(props,"dddddd")
  // const [open, setOpen] = React.useState(props === true ? true :false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  console.log(body,"fffff")
  // const handleClickOpen = () => {
  //   setOpen(props);
  // };
  useEffect(()=>{
    socket.on("connect", () => {
      console.log(socket.connected,"the socket"); // true
    });
  })
  const handleClose = () => {
    //setOpen(false);
    CloseDialog(false);
  };

  const delete_submit = (id) =>{
    console.log(id,"fff")
    var data={
      token:sessionStorage.getItem("token"),
      groupId:id
    }
    socket.emit("admin_close",data,(res)=>{
      if(res.code===200){
        handleClose()
        onClick()
        toast.success(res.message)
      }else{
        toast.error(res.message)
      }
    })

    socket.on("error",(res)=>{
      console.log(res.message,"ggg")
      toast.error(res.message)
      if(res.code===203){
        sessionStorage.clear()
      
        setTimeout(()=>{
          window.location.reload()
        },100)
      }
    })

   
    // socket.emit("authenticate_admin",{"token":sessionStorage.getItem("token")},(datas)=>{
    //   console.log(data,"gggg")
    //   if(datas){
    //     socket.emit("admin_close",data,(res)=>{
    //       console.log(res,"ggg")
    //     })
    //   }
    // })
    
    // axios.post(server.live_dev + "/close_group",{groupId:id},{headers:{"token":sessionStorage.getItem("token")}}).then((res)=>{
    //     if(res.data.code===203){
    //         toast.error(res.data.message) 
    //         sessionStorage.clear()
    //         setTimeout(()=> window.location.reload(true),1000)
    //     } else if(res.data.code===201){
    //         toast.error(res.data.message)
    //     }else{
    //         handleClose()
    //         onClick()
    //         toast.success(res.data.message)
    //     }
    // })
  } 

  // useEffect(()=>{
   
  // })
  // useEffect(()=>{
  //   props ===  true ? setOpen(true) : setOpen(false)
  // },[props])

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={isDeleteOpened}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"   
      >
        <DialogTitle id="responsive-dialog-title">
          {"Would you like to close this group?"}
        </DialogTitle>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={()=>delete_submit(body)} autoFocus>
            Close Group
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Closegroup