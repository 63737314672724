import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function Loader() {
  return (
    <Box sx={{ display: 'flex', height: '100vh', alignItems: 'center',  width:'100%' }}>
      <CircularProgress sx={{marginLeft: 'auto', marginRight: 'auto'}}/>
    </Box>
  )
}

export  function Loader1() {
  return (
    <Box sx={{ display: 'flex', height: '100%', alignItems: 'center',  width:'100%' }}>
      <CircularProgress sx={{marginLeft: 'auto', marginRight: 'auto'}}/>
    </Box>
  )
}
