import React, {lazy, Suspense} from 'react'
import {  Routes, Route, Navigate } from "react-router-dom";
import routes from './routes.js';

import Loader from './components/loader/Loader'
import ProtectedRoute from './auth/auth.js';
import Login from './pages/login/Login.jsx';


export default function Routing() {

  // const RequireAuth = ({children}) => {
  //   console.log('not ')
  //   return loggedIn ? children : <Navigate to= '/login'  /> 
  // }



  return (
  
<Routes >
        <Route element={<ProtectedRoute/>}>
        {routes.map(({ path, Component } ) => (
        <Route path={path} key={path}  element={<Suspense fallback={<Loader/>}>{Component}</Suspense>} />
      ))}
        </Route>
          <Route path="/" element={<Login/>}/>
      </Routes>
      
      
  )
}
