
import Toptag from '../../components/topTag/Toptag';
import './productDetail.css';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SwipeableTextMobileStepper from '../../components/carousel/Carousel';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../components/loader/Loader'
import Tooltip from '@mui/material/Tooltip';
import { Publish } from '@mui/icons-material';



export default function ProductDetail() {

    const location = useLocation();
    const { id } = location.state;
    console.log(id, "idddddddde")

    const [one, two] = useState({
        license_img: ['a.jpg', 'b.jpg'],
        licence_verification: 0,

    });
    const [edit1, setEdit1] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [edit, setEdit] = useState('disabled');
    const [three, four] = useState({
        pic: '',
        localPic: ''
    })


    const handleChange = (e) => {
        console.log(e.target.files[0], "three")
        if (e.target.files.length > 0) {
            let filename = e.target.files[0];

            four({
                localPic: URL.createObjectURL(filename),
                pic: filename.name,
                file: filename
            });
        }
    }


    const inputEvent = (event) => {
        two({
            ...one,
            [event.target.id]: event.target.value,
        });
    };

    const editFunction = () => {
        setEdit('')
        setEdit1('disabled')
        window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
    }

    const updateLicense = async (event) => {

        event.preventDefault();
        const updateLicStat = await axios.post('https://admin.thetapplist.com/admin/verifyLicense', { id: one._id, licence_verification: one.licence_verification })
        if (updateLicStat.data.status) {
            toast.success('License Verification Status Updated !', {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            toast.error('Error !', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }


    const updateDetails = (event) => {
        console.log(three, "three54")
        event.preventDefault();
        let obj = {
            _id: one._id, name: one.name, category_name: one.category_name,
            price: one.price, offer_price: one.offer_price, Brand_name: one.Brand_name,
            quantity: one.quantity, description: one.description, userName: one.userName,
            user_name: one.user_name, email: one.email
        }
        axios.post('https://admin.thetapplist.com/admin/updateProduct', obj).then((res) => {
            if (res.data.status) {
                toast.success('Item Updated !', { position: toast.POSITION.TOP_RIGHT })
            } else {
                toast.error('Something went wrong !', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
        setEdit('disabled')
        setEdit1('')
    }

    const getData = async () => {
        const ff = await axios.get('https://admin.thetapplist.com/admin/getProductDetails', { params: { id: id } });
        console.log(ff.data.data, "productdetail")
        two(ff.data.data);
        four({ localPic: ff.data.data.images })
        if (ff) { setIsLoading(false); }
    }

    useEffect(() => {
        setIsLoading(true);
        getData();
    }, [])

    return (

        <>
            {isLoading
                ?
                <Loader />
                :


                <div className='container-fluid'>
                    <Toptag />
                    <ToastContainer />


                    <div className="userDetCont">

                        <div className="userTitleContainer">
                            <span className="detailTitle">Product Details</span>
                            <button className='userAddButton' hidden={edit1} onClick={editFunction}>Edit</button>
                        </div>


                        <div className="mainBoxx">
                            <div className="imGCont">
                                <span className='detailTitle2'>Product Images</span>
                                <SwipeableTextMobileStepper licImg={three.localPic} />
                            </div>

                            <div className="formContainer">
                                <div className="spanContainer">
                                    <span className="userShowTitle">Product Details</span>
                                </div>
                                <div className="formInMain">

                                    <label className='myLabel'>Name</label>
                                    <input type="text" disabled={edit} value={one.name} id='name' onChange={inputEvent} className="myInput" />



                                    <label className='myLabel'>Category</label>
                                    <input type="text" disabled id='category_name' value={one.category_name} className="myInput" />


                                    <label className='myLabel'>Price</label>
                                    <input type="text" disabled={edit} id='price' onChange={inputEvent} value={one.price} className="myInput" />


                                    <label className='myLabel'>Offer Price</label>
                                    <input type="text" disabled={edit} id='offer_price' onChange={inputEvent} value={one.offer_price} className="myInput" />


                                    <label className='myLabel'>Brand Name</label>
                                    <input type="text" disabled={edit} id='Brand_name' onChange={inputEvent} value={one.Brand_name} className="myInput" />


                                    <label className='myLabel'>Quantity</label>
                                    <input type="text" disabled={edit} id='quantity' onChange={inputEvent} value={one.quantity} className="myInput" />


                                    <label className='myLabel'>Description</label>
                                    {/* <input type="text"  disabled={edit} id='category_name' onChange={inputEvent} value={one.description} className="myInput" /> */}
                                    <textarea className="myInput1" disabled={edit} id='description' onChange={inputEvent} cols="20" rows="4" value={one.description} />
                                </div>

                                <div className="spanContainer">
                                    <span className="userShowTitle">Owner Details</span>
                                </div>

                                <div className="formInMain">

                                    <label className='myLabel'>Name</label>
                                    <input type="text" disabled id='userName' onChange={inputEvent} value={one.userName} className="myInput" />



                                    <label className='myLabel'>User Name</label>
                                    <input type="text" disabled id='user_name' onChange={inputEvent} value={one.user_name} className="myInput" />


                                    <label className='myLabel'>Email</label>
                                    <input type="text" disabled id='email' onChange={inputEvent} value={one.email} className="myInput" />

                                </div>

                            </div>
                            
                        </div>
                        <div className="SubmitBtnBox">
                                <Tooltip title="Click to Update profile" >
                                    <button hidden={edit} onClick={updateDetails} className='updateDataBtn'  >
                                        Update
                                    </button>
                                </Tooltip>
                            </div>

                    </div>


                </div>


            }
        </>




    )
}















