import * as React from "react";
import './categories.css'
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';
// import Toptag from "../../components/topTag/Toptag";
import Tooltip from "@mui/material/Tooltip";
import { Add, Block, DeleteOutline, Email, RemoveRedEye, Search } from "@mui/icons-material";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import Loader from "../../components/loader/Loader";
import { Avatar, Button } from "@mui/material";
import UserBlock from "../../components/modal/UserBlock";
import DeleteUser from "../../components/modal/DeleteUser";
import moment from "moment/moment";
import server from "../../server";
import Toptag from "../../components/topTag/Toptag";
import Addcategory from "../../components/modal/Addcategory";
import Deletecategory from "../../components/modal/Deletecategory";

const columns = [
  { id: "s/n", label: "", align: "right", minWidth: 12 },
  { id: "Category", label: "Name", minWidth: 170 },
  // {
  //   id: "email",
  //   label: "email",
  //   minWidth: 170,
  //   // align: 'right',
  // },
  {
    id: "Created",
    label: "Date created",
    minWidth: 170,
    // align: 'right',
  },
  // {
  //   id: "isEmailVerified",
  //   label: "Email Verified",
  //   minWidth: 170,
  //   align: "center",
  // },
  {
    id: "actions",
    label: "Actions",
    minWidth: 170,
    align: "right",
  },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}



export default function Categories() {
  const redirect=useNavigate()
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [user_data, setUsersData] = useState([]);
  const [total_docs, setTotal] = useState(0);
  const [create_view, setCreate] = useState(false);
  const [delete_view, setDelete] = useState(false);
  const [b_userId, setUserId] = useState("");
  const [d_userId, setDlUserId] = useState("");
  const [category_name,setCategoryName]= useState('')
  const [f_total,setFtotal]= useState(0)
  const [block_status,setblockStatus]= useState()
  const [search,setSearch]=useState("")
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    getData();
  };
  let sn=1

  useEffect(() => {
    getData(page, rowsPerPage);
  }, [page, rowsPerPage]);

  const getData = async () => {
    await axios
      .get(server.live_dev+"/categories", {
        headers: { token: sessionStorage.getItem("token") },
        params: { skip: page, limit: rowsPerPage ,search:search},                                   
      })
      .then((res) => {
        if(res.data.code===203){
          // toast.error(res.data.message)
          sessionStorage.clear()
          toast.error(res.data.message)
            setTimeout(()=> window.location.reload(true),1000)
        }else if(res.data.code===201){
          toast.error(res.data.message)
        }else{ 
          setUsersData(res.data.data);   
          setIsLoading(true);
          setTotal(res.data.totalDocuments);
          setFtotal(res.data.totalDocuments);
        }
         
       
        
      });
  };
  console.log(user_data, "fffff");
  const hanldeBlock = (id,status) => {
    console.log(status,"fffff")
    setUserId(id);
    setCreate(!create_view);
    setblockStatus(status)
  };

  const hanldeDelete = (id,categorys) => {
    setDlUserId(id);
    setDelete(!delete_view);
    setCategoryName(categorys)
    getData();
  };
  const searchdata=async(e)=>{
    // setSearch(e.target.value)
    console.log(e.target.value,"the value")
    await axios
    .get(server.live_dev+"/categories", {
      headers: { token: sessionStorage.getItem("token") },
      params: { skip:e.target.value==="" ? page:"0", limit:e.target.value===""?  rowsPerPage:f_total ,search:e.target.value},                                   
    })
    .then((res) => {
      if(res.data.code===203){
        toast.error(res.data.message)
        sessionStorage.clear()
        setTimeout(()=> window.location.reload(true),1000)
      }else if(res.data.code===201){
        toast.error(res.data.message)
      }else{
      setUsersData(res.data.data);
      // if (res.data.data.length > 0) {
        setIsLoading(true);
        setTotal(res.data.totalDocuments);
      // }
    }
    });
  }
  return (
    <>
       <Toptag />
          <ToastContainer />
      {isLoading === false ? (
        <Loader />
      ) : (
        <div>

          
          
          
          <div className="container-fluid">
          <div style={{marginBottom:"30px",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
            
            <div>
            <div className="searchBarcategorys">
            <input
              type="text"
              placeholder="Search... "
              // onKeyDown={handleKeyDown}
              // id="searchtext"
              onChange={(event)=>searchdata(event)}
              className="searchBarInputcate"
            />
            <Search className="searchIconcate" />
          
          </div>
            </div>
            <div><button className="addbtn" onClick={()=>setCreate(!create_view)}>Add New Category</button></div>

          </div>
          <Paper sx={{ width: "100%", overflow: "hidden"}}>
            <TableContainer sx={{ maxHeight: 530 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {user_data.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        <TableCell align="center">
                          {sn++}
                        </TableCell>
                        {/* https://th.bing.com/th/id/OIP.7c6wkFQ9XQ2jIjGYZZALrwHaE7?pid=ImgDet&rs=1 */}
                        
                        <TableCell>{row.category}</TableCell>
                        {/* <TableCell>{row.email}</TableCell> */}
                        <TableCell>{ moment.unix(row.createdAt/1000).format("MM/DD/YYYY")}</TableCell>
                     
                        <TableCell align="right">
                          {/* <Tooltip title="view profile">
                            <RemoveRedEye className="visibilityIc" />
                          </Tooltip> */}
                          {/* <Link
                            to={`/sellers/details`}
                            state={{ id: row._id }}
                          >
                            <Tooltip title="view profile">
                              <RemoveRedEye className="visibilityIc" />
                            </Tooltip>
                          </Link> */}
                          {/* <Tooltip
                            title={
                              row.adminBlock === false
                                ? "block user"
                                : "unblock user"
                            }
                          >
                            <Block
                              className={`block ${"block"}`}
                              id="isActive"
                              value={row.adminBlock}
                              onClick={() => hanldeBlock(row._id,row.adminBlock)}
                            />
                          </Tooltip> */}
                          <Tooltip title="Edit Category">
                            <EditIcon
                              className="userListDelete"
                              onClick={() => hanldeDelete(row._id,row.category)}
                            />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={total_docs}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          </div>
         
          <Addcategory
            isDeleteOpened={create_view}
            body={d_userId}
            onClick={getData}
            CloseDialog={() => setCreate(false)}
          />

        <Deletecategory
                    isDeleteOpened={delete_view}
                    body={d_userId}
                    category_name={category_name}
                    onClick={getData}
                    CloseDialog={() => setDelete(false)}
                  />

          {/* {
            block_view === true ? (
                <UserBlock props={block_view}/>
            ) : <UserBlock props={block_view}/>
        } */}
        </div>
      )}
    </>
  );
}
