import React from 'react'
import './sidebar.css';

import { NavLink} from 'react-router-dom';


import {Home,QueryStats,Category,AccountCircle,TrendingUp,Message,Feedback,Email,Storefront,Leaderboard,CurrencyBitcoin, LocalGroceryStore, LocalMall, Loyalty, Flag, Policy, FindInPage, ReportProblem, Inventory, ProductionQuantityLimits} from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import CategoryIcon from '@mui/icons-material/Category';
import Groups2Icon from '@mui/icons-material/Groups2';
import DashboardIcon from '@mui/icons-material/Dashboard';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
export default function Sidebar() {
  return (
    <div className='sidebar'>
        <div className="sidebarWrapper">

            <div className="sidebarMenu">
                <h3 className="sidebarTitle">Dashboard</h3>
                <ul className="sidebarList">
                    <NavLink to= '/home' className='link'>
                    <li className="sidebarListItem">
                    <Home className='sidebarIcon '/>
                    Home
                    </li>
                    </NavLink>
                    {/* <NavLink to= '/analytics' className='link'>
                    <li className="sidebarListItem">
                    <QueryStats className='sidebarIcon'/>
                    Analytics
                    </li>
                    </NavLink> */}
                   
                </ul>
            </div>
            <div className="sidebarMenu">
                <h3 className="sidebarTitle">User Management</h3>
                <ul className="sidebarList">

                    <NavLink to='/home/user_management' className='link'>
                    <li className="sidebarListItem">
                    <AccountCircle className='sidebarIcon'/>
                    Users
                    </li>
                    </NavLink>
                    {/* <NavLink to='/sellers' className='link'>
                    <li className="sidebarListItem">
                    <Storefront className='sidebarIcon'/>
                    Sellers
                    </li>
                     </NavLink> */}
                     {/*
                    <li className="sidebarListItem">
                    <CurrencyBitcoin className='sidebarIcon'/>
                    Transactions
                    </li>
                    <li className="sidebarListItem">
                    <Leaderboard className='sidebarIcon'/>
                    Reports
                    </li> */}
                </ul>
            </div>
            <div className="sidebarMenu">
                <h3 className="sidebarTitle">Management</h3>
                <ul className="sidebarList">
                <NavLink to='/home/group_management' className='link'>
                    <li className="sidebarListItem">
                    <Groups2Icon className='sidebarIcon'/>
                     Group Management
                    </li>
                    </NavLink>
                    <NavLink to='/home/category' className='link'>
                    <li className="sidebarListItem">
                    <CategoryIcon className='sidebarIcon'/>
                    Category
                    </li>
                    </NavLink>
                    <NavLink to='/home/items' className='link'>
                    <li className="sidebarListItem">
                    <LocalGroceryStoreIcon className='sidebarIcon'/>
                    Item
                    </li>
                     </NavLink>
                    {/* <NavLink to='/categories' className='link'>
                    <li className="sidebarListItem">
                    <Category className='sidebarIcon'/>
                    Categories
                    </li>
                     </NavLink>
                    <NavLink to='/orders' className='link'>
                    <li className="sidebarListItem">
                    <LocalMall className='sidebarIcon'/>
                    Orders
                    </li>
                     </NavLink> */}
                    {/* <NavLink to='/subscriptions' className='link'>
                    <li className="sidebarListItem">
                    <Loyalty className='sidebarIcon'/>
                    Subscription
                    </li>
                     </NavLink> */}
                    {/* <NavLink to='/disputes' className='link'>
                    <li className="sidebarListItem">
                    <Flag className='sidebarIcon'/>
                    Disputes
                    </li>
                     </NavLink> */}
                     
                </ul>
            </div>
            <div className="sidebarMenu">
                <h3 className="sidebarTitle">Content Management</h3>
                <ul className="sidebarList">
                <NavLink to='/privacy_Policy' className='link'>
                    <li className="sidebarListItem">
                    <VerifiedUserIcon className='sidebarIcon'/>
                    Privacy Policy
                    </li>
                    </NavLink>
                    <NavLink to='/terms_&_Conditions' className='link'>
                    <li className="sidebarListItem">
                    <FindInPage className='sidebarIcon'/>
                    Terms and conditions
                    </li>
                    </NavLink>
                    {/* <NavLink to='/disclaimer' className='link'>
                    <li className="sidebarListItem">
                    <ReportProblem className='sidebarIcon'/>
                    Disclaimer
                    </li>
                    </NavLink> */}
                    <NavLink to='/about_app' className='link'>
                    <li className="sidebarListItem">
                    <InfoIcon className='sidebarIcon'/>
                    About App
                    </li>
                    </NavLink>
                    {/* <NavLink to='/faq' className='link'>
                    <li className="sidebarListItem">
                    <QuestionAnswerIcon className='sidebarIcon'/>
                    FAQ
                    </li>
                    </NavLink> */}
                    
                </ul>
            </div>
            
        </div>
    </div>

  )

}
