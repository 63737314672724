import React, { useEffect, useState } from "react";
import axios from "axios";
import "./buyer.css";
import {
  DataGrid,
  getDataGridUtilityClass,
  GridToolbarExport,
} from "@mui/x-data-grid";
import {
  DeleteOutline,
  Email,
  Block,
  CenterFocusWeakOutlined,
  RemoveRedEye,
} from "@mui/icons-material";
import Chip from "@mui/material/Chip";
import { Link } from "react-router-dom";
import Toptag from "../../components/topTag/Toptag";
import Tooltip from "@mui/material/Tooltip";
import { Search } from "@mui/icons-material/";
import Loader from "../../components/loader/Loader";
import { ToastContainer, toast } from "react-toastify";

export default function Buyer() {
  // const [one, two] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  // const [pageSize, setPageSize] = useState(10);

  // const handleKeyDown = (e) => {
  //   console.log(e.target.value);
  //   if (e.target.value === "") {
  //     if (e.key === " ") {
  //       e.preventDefault();
  //     }
  //   }
  // };

  // const blockUser = async (_id, isActive) => {
  //   var data = { _id, isActive };
  //   const blockUnblock = await axios.post(
  //     "https://admin.thetapplist.com/admin/blockUnblock",
  //     data
  //   );
  //   getData();
  // };

  // const filterFunction = async (event) => {
  //   let val = event.target.value;
  //   const ff = await axios.get("https://admin.thetapplist.com/admin/getSellers", {
  //     params: { searchtext: val },
  //   });
  //   two(ff.data.arr);
  // };
  // const handleDelete = async (_id) => {
  //   var data = { _id };
  //   // const blockUnblock = await axios.post('https://admin.thetapplist.com/admin/deleteUser', data);
  //   // if(res.data.status) {
  //   //   toast.success('Profile Updated !', {position: toast.POSITION.TOP_RIGHT})
  //   // }else{
  //   //   toast.error('Something went wrong !', {position: toast.POSITION.TOP_RIGHT
  //   //   });
  //   // }
  //   two(one.filter((item) => item._id !== _id));
  //   toast.success("Temporary Deletion !", {
  //     position: toast.POSITION.TOP_RIGHT,
  //   });

  //   getData();
  // };

  // const getData = async () => {
  //   const ff = await axios.get("https://admin.thetapplist.com/admin/getBuyers", {
  //     params: { searchtext: "" },
  //   });
  //   two(ff.data.arr);

  //   if (ff) {
  //     setIsLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   setIsLoading(true);
  //   getData();
  // }, []);

  // const columns = [
  //   // { field: 'id', headerName: 'ID', minWidth: 250 ,maxWidth: 290, flex:0.9 },
  //   // { field: 'number', headerName: '#', minWidth: 100, maxWidth: 140, flex: 0.9 },
  //   {
  //     field: "user_name",
  //     headerName: "User",
  //     minWidth: 150,
  //     maxWidth: 280,
  //     flex: 1,
  //     renderCell: (params) => {
  //       return (
  //         <div className="userListUser">
  //           <img src={params.row.profile_pic} alt="" className="userListImg" />
  //           {params.row.user_name}
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     field: "email",
  //     headerName: "Email",
  //     minWidth: 180,
  //     maxWidth: 320,
  //     flex: 1,
  //   },
  //   {
  //     field: "created_at",
  //     headerName: "Joined Since",
  //     minWidth: 100,
  //     maxWidth: 290,
  //     flex: 1,
  //   },
  //   {
  //     field: "emailVerified",
  //     headerName: "Email",
  //     description: "This column has a value getter and is not sortable.",
  //     minWidth: 150,
  //     maxWidth: 300,
  //     flex: 1,

  //     renderCell: (params) => {
  //       return (
  //         <>
  //           <Chip
  //             className="chipEmail"
  //             icon={
  //               <Email
  //                 style={{ color: "white", fontSize: "20px" }}
  //                 className={params.row.emailVerified.toString()}
  //               />
  //             }
  //             label={params.row.emailVerified.toString()}
  //           />
  //         </>
  //       );
  //     },
  //   },

  //   {
  //     field: "action",
  //     headerName: "Action",
  //     minWidth: 130,

  //     renderCell: (params) => {
  //       return (
  //         <>
  //           <Link to={`/buyers/details`} state={{ id: params.row._id }}>
  //             <Tooltip title="view profile">
  //               <RemoveRedEye className="visibilityIc" />
  //             </Tooltip>
  //           </Link>
  //           <Tooltip title="Block User">
  //             <Block
  //               className={`block ${params.row.isActive.toString()}`}
  //               onClick={() => blockUser(params.row._id, params.row.isActive)}
  //             />
  //           </Tooltip>
  //           <Tooltip title="Delete User">
  //             <DeleteOutline
  //               className="userListDelete"
  //               onClick={() => handleDelete(params.row._id)}
  //             />
  //           </Tooltip>
  //         </>
  //       );
  //     },
  //   },
  // ];

  // return (
  //   <>
  //     {isLoading ? (
  //       <Loader />
  //     ) : (
  //       <div className="container-fluid">
  //         <Toptag />
  //         <ToastContainer />
  //         <div className="searchBar">
  //           <input
  //             type="text"
  //             placeholder="Search... "
  //             onKeyDown={handleKeyDown}
  //             id="searchtext"
  //             onChange={filterFunction}
  //             className="searchBarInput"
  //           />
  //           <Search className="searchIcon" />
  //         </div>
  //         <div className="mainBox">
  //           <div style={{ height: 530 }}>
  //             <DataGrid
  //               sx={{
  //                 ".MuiDataGrid-columnHeaderTitle": {
  //                   fontWeight: "600",
  //                   fontSize: "15px",
  //                 },
  //                 ".MuiDataGrid-columnHeaders": {
  //                   border: "ridge",
  //                 },
  //               }}
  //               disableSelectionOnClick
  //               rows={one}
  //               rowCount={one.totalCount}
  //               columns={columns}
  //               pageSize={pageSize}
  //               disableColumnMenu
  //               onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
  //               rowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
  //               components={{ Toolbar: GridToolbarExport }}
  //               // checkboxSelection
  //             />
  //           </div>
  //         </div>
  //       </div>
  //     )}
  //   </>
  // );
}
