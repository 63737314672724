import './buyerDetail.css';
import Toptag from '../../components/topTag/Toptag';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../components/loader/Loader'
import Tooltip from '@mui/material/Tooltip';


export default function BuyerDetail() {
  const location = useLocation();
  const { id } = location.state;

  const [one, two] = useState({});
  const [edit, setEdit] = useState('disabled');
  const [edit1, setEdit1] = useState('');
  const [isLoading, setIsLoading] = useState(false);



  const editFunction = () => {
    setEdit('')
    setEdit1('disabled')
    window.scrollTo({   top: document.documentElement.scrollHeight, behavior: 'smooth' }); 
  }

  const handleKeyDown = e => {
    console.log(e.target.value)
    if (e.target.value === '') {
      if (e.key === " ") {
        e.preventDefault();
      }
    }
  };

  const inputEvent = (e) => {
    const { id, value } = e.target;
    two((prevalue) => {
      return {
        ...prevalue,
        [id]: value
      };
    });
  };


  const updateUserdata = (e) => {
    e.preventDefault();

    let obj = { name: one.name, _id: one._id, user_name: one.user_name, contact: one.contact }
    axios.post('https://admin.thetapplist.com/admin/updateUser_Profile', obj).then((res) => {
      setEdit('disabled')
      setEdit1('')
      if (res.data.status) {
        toast.success('PROFILE UPDATED !', { position: toast.POSITION.TOP_RIGHT })
      } else {
        toast.error('Something went wrong !', {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    });
  }

  useEffect(() => {
    setIsLoading(true);

    const getData = async () => {
      const ff = await axios.get('https://admin.thetapplist.com/admin/getUserDetails', { params: { id: id } });
      two(ff.data.data);
      if (ff) { setIsLoading(false); }

    }
    getData();
  }, [])



  return (
    <>
      {
        isLoading
          ?
          <Loader />
          :
          <div className='container-fluid'>
            <ToastContainer />
            <Toptag />
        
              <div className="userDetCont">

                <div className="userTitleContainer">
                  <span className="detailTitle">User Details</span>
                  <button className='userAddButton' hidden={edit1} onClick={editFunction} >Edit</button>
                </div>

                <div className="mainBoxx">
                  <div className="imGCont">
                    <img src={one.profile_pic} onClick={() => window.open(one.profile_pic)} className='myImg' alt="" />
                  </div>
                  
                  <div className="formContainer">
                    <div className="formInMain">
                      
                      <label className='myLabel'>Name</label>
                      <input type="text" disabled={edit} onKeyDown={handleKeyDown} onChange={inputEvent} id='name' value={one.name} className="myInput" />

                      <label className='myLabel'>Username</label>
                      <input type="text" disabled={edit} onKeyDown={handleKeyDown} onChange={inputEvent} id='user_name' value={one.user_name} className="myInput" />


                      <label className='myLabel'>Email</label>
                      <input type="text" readOnly disabled value={one.email} className="myInput" />


                      <label className='myLabel'>Contact</label>
                      <input type="number" disabled={edit} onKeyDown={handleKeyDown} onChange={inputEvent} id='contact' value={one.contact} className="myInput" />

                      </div>
                    </div>
                  </div>
                  <div className="SubmitBtnBox">
                        <Tooltip title="Click to Update profile" >
                          <button hidden={edit} className='updateDataBtn' onClick={updateUserdata} >
                            Update
                          </button>
                        </Tooltip>
                      </div>
                </div>
              </div>
        
      }
          </>

  )
}
