import './login.css';
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import server from '../../server';

export default function Login() {
	const navigate = useNavigate();
	const [style, setStyle] = useState("container1 left-panel-active");
	const [login, setLogin] = useState({
		email: "",
		password: "",
		email_recover: ""
	});

	const changeStyle1 = () => {
		setStyle("container1 right-panel-active");
	};
	const changeStyle2 = () => {
		setStyle("container1 left-panel-active");
	};

	const handleKeyDown = e => {
		if (e.key === " ") {
			e.preventDefault();
		}
	};

	const inputEvent = (e) => {
		const { id, value } = e.target;
		setLogin((prevalue) => {
			return {
				...prevalue,
				[id]: value
			};
		});
	};

	const submitForm = (event) => {
		event.preventDefault();
		let obj = { ...login };
		console.log(obj, "obj")
		axios.post(server.live_dev+'/login', obj).then((res) => {
			console.log(res , "I am the data you nededeeeddedfdfffdfdfgdfdfvdbvvbvvbvvbbvv")
			if (res.data.status) {
				console.log('in')
				toast.success('Logged in !', {
					position: toast.POSITION.TOP_RIGHT
				});

				console.log(res.data.results, "This is the data I wantttttttttt") 
				sessionStorage.setItem('email', res.data.results.email)
				sessionStorage.setItem('token', res.data.results.x_token)
				sessionStorage.setItem('profile_pic', res.data.results.profile_pic)
				navigate('/home')
				setTimeout(window.location.reload.bind(window.location), 10);
			} else {
				toast.error('Wrong Credentials!', {
					position: toast.POSITION.TOP_RIGHT
				});
			}

		}).catch((err) => {
			console.log(err, "err")
			window.alert(err.message)
		})
	};

	const submitFormForgotPass = (event) => {
		event.preventDefault();
		let obj = { ...login };
		let email=obj.email_recover
		console.log(obj, "obj")
		axios.post(server.live_dev+'/forgot_password', {email:email}).then((res) => {
			if (res.data.status) {

				toast.success('Check email please !', {
					position: toast.POSITION.TOP_RIGHT
				});
				setTimeout(window.location.reload.bind(window.location), 1000);
			} else {
				toast.error('Wrong Credentials !', {
					position: toast.POSITION.TOP_RIGHT
				});
			}

		}).catch((err) => {
			console.log(err, "err")
			window.alert(err.message)
		})
	};




	return (
		<>
			<ToastContainer />

			<div className="fill_center">
				<div className={style} id="container">

					<div className="form-container sign-up-container">
						<form onSubmit={submitFormForgotPass}>
							<img src="/images/Logo.png" alt="" className='loginLogo' />
							<h1 className='heading2'>Recover Account</h1>
							<input type="email" id='email_recover' onKeyDown={handleKeyDown}  onChange={inputEvent} required={true} placeholder="Email" />
							<button className='submitButton' >Submit</button>
						</form>
					</div>

					<div className="form-container sign-in-container">
						<form onSubmit={submitForm}>
							<img src="/images/Logo.png" alt="" className='loginLogo' />
							<h1 className='heading1'>SIGN IN</h1>
							<input type="email" id='email' onKeyDown={handleKeyDown}  onChange={inputEvent} required={true} placeholder="Email" />
							<input type="password" id='password' onKeyDown={handleKeyDown}  onChange={inputEvent} required={true} placeholder="Password" />
							<button className='singinButton' >Sign In</button>
						</form>
					</div>

					<div className="overlay-container">
						<div className="overlay">
							<div className="overlay-panel overlay-left">
								<h1>Hello, Admin!</h1>
								<p>To keep connected with us please login with your personal info</p>
								<button className="ghost" id="signIn" onClick={changeStyle2}>Sign In</button>
							</div>
							<div className="overlay-panel overlay-right">
								<h1>Welcome Back!</h1>
								<p>To recover your account password, Click below!</p>
								<button className="ghost" id="signUp" onClick={changeStyle1}>Forgot Password?</button>
							</div>
						</div>
					</div>
				</div>
			</div>



			{/* <footer>
	<p>
		Created with <i className="fa fa-heart"></i> by
		<a target="_blank" href="https://florin-pop.com">Florin Pop</a>
		- Read how I created this and how you can join the challenge
		<a target="_blank" href="https://www.florin-pop.com/blog/2019/03/double-slider-sign-in-up-form/">here</a>.
	</p>
</footer> */}
		</>
	)
}

