import React, { useEffect, useState } from "react";
import axios from "axios";
import "./seller.css";
import { DataGrid, GridToolbar, GridToolbarExport } from "@mui/x-data-grid";
import {
  Email,
  Block,
  CenterFocusWeakOutlined,
  DeleteOutline,
  RemoveRedEye,
} from "@mui/icons-material";
import Chip from "@mui/material/Chip";
import { Link } from "react-router-dom";
import Toptag from "../../components/topTag/Toptag";
import Tooltip from "@mui/material/Tooltip";
import { Search } from "@mui/icons-material/";
import Loader from "../../components/loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import { flushSync } from "react-dom";
import Users from "./Users";
import server from "../../server";
const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
  {
    id: 'population',
    label: 'Population',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'size',
    label: 'Size\u00a0(km\u00b2)',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'density',
    label: 'Density',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const rows = [
  createData('India', 'IN', 1324171354, 3287263),
  createData('China', 'CN', 1403500365, 9596961),
  createData('Italy', 'IT', 60483973, 301340),
  createData('United States', 'US', 327167434, 9833520),
  createData('Canada', 'CA', 37602103, 9984670),
  createData('Australia', 'AU', 25475400, 7692024),
  createData('Germany', 'DE', 83019200, 357578),
  createData('Ireland', 'IE', 4857000, 70273),
  createData('Mexico', 'MX', 126577691, 1972550),
  createData('Japan', 'JP', 126317000, 377973),
  createData('France', 'FR', 67022000, 640679),
  createData('United Kingdom', 'GB', 67545757, 242495),
  createData('Russia', 'RU', 146793744, 17098246),
  createData('Nigeria', 'NG', 200962417, 923768),
  createData('Brazil', 'BR', 210147125, 8515767),
];

export default function Seller() {
  const [one, two] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search,setSearch]= useState("")
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const blockUser = async (_id, isActive) => {
    var data = { _id, isActive };
    const blockUnblock = await axios.post(
      "https://admin.thetapplist.com/admin/blockUnblock",
      data
    );
    getData();
  };

  const handleKeyDown = (e) => {
    console.log(e.target.value);
    
    if (e.target.value === "") {
      if (e.key === "") {
        e.preventDefault();
      }
    }else{
      toast.error(e.target.value)
    }
  };

  const filterFunction = async (event) => {
    let val = event.target.value;
    const ff = await axios.get(server.live_dev+"/get_users", {
      params: { searchtext: val },
    });
    two(ff.data.arr);
  };

  const handleDelete = async (_id) => {
    var data = { _id };
    // const blockUnblock = await axios.post('https://admin.thetapplist.com/admin/deleteUser', data);
    // if(res.data.status) {
    //   toast.success('Profile Updated !', {position: toast.POSITION.TOP_RIGHT})
    // }else{
    //   toast.error('Something went wrong !', {position: toast.POSITION.TOP_RIGHT
    //   });
    // }
    two(one.filter((item) => item._id !== _id));
    toast.success("Temporary Deletion !", {
      position: toast.POSITION.TOP_RIGHT,
    });

    getData();
  };

  const getData = async () => {
    const ff = await axios.get(server.live_dev+"/get_users",{headers:{"token":sessionStorage.getItem("token")},params:{skip:0,limit:5}});
    setPageSize(ff.data.totalDocuments)
    console.log(ff.data.data," thishsishishishisishishihsi")
    const datas= ff.data.data.map((element,index)=>{
        // element.id=index
        return element
    })
    two(datas);
    if (ff) {
      setIsLoading(false);
    }
    
  };

  useEffect(() => {
    setIsLoading(true);
    // getData();
  }, []);

  const searching=()=>{
    setSearch()
  }
  
  // ];
  const columns = [
    {
      field: "user_name",
      headerName: "User",
      minWidth: 180,
      maxWidth: 300,
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="userListUser"> 
            {/* <img src={params.row.profile_pic} alt="" className="userListImg" /> */}
            {''}
          </div>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Joined Since",
      minWidth: 100,
      maxWidth: 230,
      flex: 1,
    },
    {
      field: "emailVerified",
      headerName: "Email",
      description: "This column has a value getter and is not sortable.",
      minWidth: 150,
      mXWidth: 250,
      flex: 1,

      renderCell: (params) => {
        return (
          <>
            <Chip
              className="chipEmail"
              icon={
                <Email
                  className={''}
                  style={{ color: "white", fontSize: "20px" }}
                />
              }
              label={''}
            />
          </>
        );
      },
    },
    
    {
      field: "action",
      headerName: "Action",
      minWidth: 150,
      maxWidth: 230,

      renderCell: (params) => {
        return (
          <>
            <Link to={`/sellers/details`} state={{ id: 'gdgfggfgf' }}>
              <Tooltip title="view profile">
                <RemoveRedEye className="visibilityIc" />
              </Tooltip>
            </Link>
            <Tooltip
              title={params.row.adminBlock ? "block user" : "unblock user"}
            >
              <Block
                className={`block ${params.row.adminBlock.toString()}`}
                id="isActive"
                value={one.adminBlock}
                onClick={() => blockUser(params.row._id, params.row.adminBlock)}
              />
            </Tooltip>
            <Tooltip title="Delete User">
              <DeleteOutline
                className="userListDelete"
                onClick={() => handleDelete(params.row._id)}
              />
            </Tooltip>
          </>
        );
      },
    }]

    // const cellRenderer=() =>{
    //   return 
    // }
    const cellRenderer = (props) => {
      return <Users {...props} />;
    };
  

  return (
    <>
      {isLoading === false ? (
        <Loader />
      ) : (
       
        <div className="container-fluid">
          <Toptag />
          <ToastContainer />

          <div className="searchBar">
            <input
              type="text"
              placeholder="Search... "
              // onKeyDown={handleKeyDown}
              id="searchtext"
              onChange={(e)=>setSearch(e.target.value)}
              className="searchBarInput"
            />
            <Search className="searchIcon" />
          </div>
          <div className="mainBox">
            <div style={{ height: "auto" }}>
            {/* <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 800 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      </Paper> */}
              {/* <DataGrid
                className="gridBox"
                sx={{
                  ".MuiDataGrid-columnHeaderTitle": {
                    fontWeight: "600",
                    fontSize: "15px",
                  },
                  ".MuiDataGrid-columnHeaders": {
                    border: "ridge",
                  },
                  ".MuiInputBase-root": {
                    marginTop: "-15px",
                  },
                }}
                disableSelectionOnClick
                // cellRenderer={<Users/>}
                rows={[{id : "1"},{id : "2"},{id : "3"},{id : "4"},{id : "5"},{id : "6"},{id : "7"},{id : "8"},{id : "9"}]}
                columns={columns}
                disableColumnMenu
                
                cellRenderer={(
                  <Users />
                )}
                
                // pageSize={0}
                
                // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                // rowsPerPageOptions={[10, 20, 30, 50, 100]}
                // components={{ Toolbar: GridToolbarExport }}
              /> */}
              <Users body={search}/>
            </div>
          </div>
       </div>
      )}
    </>
  );
}
