import * as React from "react";
import './group.css'
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import PeopleIcon from '@mui/icons-material/People';
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
// import Toptag from "../../components/topTag/Toptag";
import Tooltip from "@mui/material/Tooltip";
import { Add, Block, DeleteOutline, Email, RemoveRedEye, Search } from "@mui/icons-material";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import Loader from "../../components/loader/Loader";
import { Avatar, Button } from "@mui/material";
import UserBlock from "../../components/modal/UserBlock";
import DeleteUser from "../../components/modal/DeleteUser";
import moment from "moment/moment";
import server from "../../server";
import Toptag from "../../components/topTag/Toptag";
import Addcategory from "../../components/modal/Addcategory";
import Deletecategory from "../../components/modal/Deletecategory";
import Additem from "../../components/modal/Additem";
import Deleteitem from "../../components/modal/Deleteitem";
import Members from "../../components/modal/Members";
import Closegroup from "../../components/modal/Closegroup";
import {socket} from '../../socket/socket' 


const columns = [
  // { id: "s/n", label: "", align: "right", minWidth: 12 },
  { id: "image", label: "", align: "right", minWidth: 12 },
  { id: "group_name", label: "Group Name", minWidth: 170 },
  { id: "members", label: "Members", minWidth: 70 },
  // {
  //   id: "email",
  //   label: "email",
  //   minWidth: 170,
  //   // align: 'right',
  // },
  {
    id: "reports",
    label: "reports",
    minWidth: 70,
    align: 'left',
  },
  {
    id: "Created",
    label: "Date created",
    minWidth: 70,
    align: 'left',
  },
  // {
  //   id: "isEmailVerified",
  //   label: "Email Verified",
  //   minWidth: 170,
  //   align: "center",
  // },
  {
    id: "actions",
    label: "Actions",
    minWidth: 70,
    align: "right",
  },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}




export default function  Groups() {
  const redirect = useNavigate()
  const [isConnected,setIsconnected]=useState(false)
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [user_data, setUsersData] = useState([]);
  const [total_docs, setTotal] = useState(0);
  const [f_total,setFtotal]=useState(0)
  const [create_view, setCreate] = useState(false);
  const [delete_view, setDelete] = useState(false);
  const [b_userId, setUserId] = useState("");
  const [d_userId, setDlUserId] = useState("");
  const [block_status,setblockStatus]= useState()
  const [search,setSearch]=useState("")
  const [category,setCategory]=useState([])
  const [members,setMembers]=useState([])
  const [group_name,setGroup_name]=useState('')
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    getData();
  };
  let sn=1
  // console.log(isConnected,"the_socket_era")
  useEffect(() => {
    getData(page, rowsPerPage);
    //  socket.on("error",(res)=>{
    //   if(res.code===203){
    //     sessionStorage.clear()
    //     toast.error(res.message)
    //     setTimeout(()=>{
    //       window.location.reload()
    //     },10)
    //   }
    // })
  }, [page, rowsPerPage]);

  const getData = async () => {
    await axios
      .get(server.live_dev + "/category_for_dropdown", {
        headers: { token: sessionStorage.getItem("token") },
      }).then((res)=>{
        if(res.data.code===200){
            setCategory(res.data.data)
        }else if(res.data.code===203){
          sessionStorage.clear()
          toast.error("Please login again")
          setTimeout(()=>{
            window.location.reload()
          },10)
        }
      })
      
     
    await axios
      .get(server.live_dev+"/get_groups", {
        headers: { token: sessionStorage.getItem("token") },
        params: { skip: search==='' ?  page:"0", limit: search==='' ? rowsPerPage :f_total,search:search},                                   
      })
      .then((res) => {
        console.log(res.data.data,"ssss")
      
        if (res.data.data!==undefined && res.data.data.length > 0) {
          setIsLoading(true);
          setTotal(res.data.totalDocuments);
          setFtotal(res.data.totalDocuments);
          setUsersData(res.data.data);
        }else{
          setUsersData([]);
          setIsLoading(true);
        }
      });
  };
  console.log(user_data, "fffff");
  const hanldeBlock = (id,status) => {
    console.log(status,"fffff")
    setUserId(id);
    setCreate(!create_view);
    setblockStatus(status)
  };

  const hanldeDelete = (id) => {
    setDlUserId(id);
    setDelete(!delete_view);
    getData();
  };
  const searchdata=async(e)=>{
    // setSearch(e.target.value)
    console.log(e.target.value,"the value")
    await axios
    .get(server.live_dev+"/get_groups", {
      headers: { token: sessionStorage.getItem("token") },
      params: { skip: e.target.value==='' ?  page:"0", limit: e.target.value==='' ? rowsPerPage :f_total,search:e.target.value},                                   
    })
    .then((res) => {
      if(res.data.code===203){
        toast.error(res.data.message)
        sessionStorage.clear()
        setTimeout(()=> window.location.reload(true),1000)
      }else if(res.data.code===201){
        toast.error(res.data.message)
      }else{
    
    
      if (res.data.data.length > 0) {
        setIsLoading(true);
        setTotal(res.data.totalDocuments);
        setUsersData(res.data.data);
      }else{
        setUsersData([])
        setIsLoading(true);
      }
    }
    });
  }
  const viewMemebers=(members,group_name)=>{
    setCreate(!create_view)
    setMembers(members)
    setGroup_name(group_name)
  }

  const redirectTo=(id,group_name)=>{
    redirect("/home/reported",{state:{id:id,group_name:group_name}})
  }
  return (
    <>
       <Toptag />
          <ToastContainer />
      {isLoading === false ? (
        <Loader />
      ) : (
        <div>

          
          
          
          <div className="container-fluid">
          <div style={{marginBottom:"30px",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
            
            <div>
            <div className="searchBarcategorys">
            <input
              type="text"
              placeholder="Search... "
              // onKeyDown={handleKeyDown}
              // id="searchtext"
              onChange={(event)=>searchdata(event)}
              className="searchBarInputcate"
            />
            <Search className="searchIconcate" />
          
          </div>
            </div>
            {/* <div><button className="addbtn" onClick={()=>setCreate(!create_view)}>Add New Item</button></div> */}

          </div>
          <Paper sx={{ width: "100%", overflow: "hidden"}}>
            <TableContainer sx={{ maxHeight: 530 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {user_data.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {/* <TableCell align="center">
                          {sn++}
                        </TableCell> */}
                        <TableCell>
                          <Avatar
                            alt="Remy Sharp"
                            // src={row.itemImage}
                            src={row.groupProfile ==="" ? "": server.live_image+"/groupProfile/"+row.groupProfile}
                          />
                        </TableCell>
                        {/* https://th.bing.com/th/id/OIP.7c6wkFQ9XQ2jIjGYZZALrwHaE7?pid=ImgDet&rs=1 */}
                        
                        <TableCell>{row.group_name}</TableCell>
                        <Tooltip title="Click to view the list">
                        <TableCell onClick={()=>viewMemebers(row.members,row.group_name)}>
                        
                        {row.members_count}
                     </TableCell>
                        </Tooltip>
                        
                        <Tooltip title="Click to view the report list">
                        <TableCell onClick={()=> row.report_count >0 ? redirectTo(row._id,row.group_name) :""}>{row.report_count}</TableCell>
                        </Tooltip>
                        {/* <TableCell>{row.email}</TableCell> */}
                        <TableCell>{ moment.unix(row.createdAt/1000).format("MM/DD/YYYY")}</TableCell>
                     
                        <TableCell align="right">
                          {/* <Tooltip title="view profile">
                            <RemoveRedEye className="visibilityIc" />
                          </Tooltip> */}
                          {/* <Link
                            to={`/sellers/details`}
                            state={{ id: row._id }}
                          >
                            <Tooltip title="view profile">
                              <RemoveRedEye className="visibilityIc" />
                            </Tooltip>
                          </Link> */}
                          <Tooltip
                            title="View members"
                          >
                            <PeopleIcon
                              className={`block ${"block"}`}
                              id="isActive"
                              style={{"color":"green"}}
                              onClick={()=>viewMemebers(row.members,row.group_name)}
                            />
                          </Tooltip>
                         
                          {/* <Tooltip title="View members">
                            <PeopleIcon
                              className="userList"
                              onClick={() => hanldeDelete(row._id)}
                            />
                          </Tooltip> */}
                          <Tooltip title="Close group">
                            <DeleteOutline
                              className="userListDelete"
                              onClick={() => hanldeDelete(row._id,row.adminId)}
                            />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={total_docs}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          </div>
         
          <Members
            isDeleteOpened={create_view}
            body={members}
            group={group_name}
            onClick={getData}
            CloseDialog={() => setCreate(false)}
          />

        <Closegroup
                    isDeleteOpened={delete_view}
                    body={d_userId}
                    onClick={getData}
                    CloseDialog={() => setDelete(false)}
                  />

          {/* {
            block_view === true ? (
                <UserBlock props={block_view}/>
            ) : <UserBlock props={block_view}/>
        } */}
        </div>
      )}
    </>
  );
}


