import { Translate } from '@mui/icons-material';
import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Bar, Line, Tooltip, Legend } from 'recharts';

const data = [
  { name: 'Buyers', value: 7 },
  { name: 'Sellers', value: 14 }

];

const COLORS = ['#70ad47', '#f5c001'];

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
  
    </text>
  );
};

export default function PieCharts() {

    return (

    <div style={{ width: '100%', height: 350 ,  marginTop: '20px' }}>
      <ResponsiveContainer >
        <PieChart width={400} height={400}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={80}
            isAnimationActive={true}
           
            dataKey="value"
          >
            
          {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
          
          </Pie>
          <Tooltip sx={{backgroundColor: 'inherit', color: 'white'}}/>
          <Legend />
        
           <Bar dataKey="Buyers"  />
           <Bar dataKey="Sellers" />
        </PieChart>
      </ResponsiveContainer>
      </div>

    );
  
}


