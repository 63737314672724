import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Avatar, Tooltip } from '@mui/material';
import server from '../../server';
import { Link } from 'react-router-dom';
import { RemoveRedEye } from '@mui/icons-material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const columns = [
    { id: "profile_pic", label: "", align: "right", minWidth: 12 },
    { id: "name", label: "Name", minWidth: 70 },
    // { id: "admin", label: "Admin", minWidth: 70 },
    {
        id: "actions",
        label: "Actions",
        minWidth: 70,
        align: "center",
      }
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}



export default function MembersList({members}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {members
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                     <TableCell>
                          <Avatar
                            alt="Remy Sharp"
                            src={row.profile_photo ==="" ? server.user_static: server.live_image+row.profile_photo}
                          />
                        </TableCell>
                        <TableCell>{row.full_name}</TableCell>
                        {/* <TableCell><AdminPanelSettingsIcon style={{color:row.admin===true ? 'green':'red'}}/></TableCell> */}
                        <TableCell align="center">
                          {/* <Tooltip title="view profile">
                            <RemoveRedEye className="visibilityIc" />
                          </Tooltip> */}
                          <Link
                            to={`/users/details`}
                            state={{ id: row._id }}
                          >
                            <Tooltip title="view profile">
                              <RemoveRedEye className="visibilityIc" />
                            </Tooltip>
                          </Link>
                          
                        </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
     
    </Paper>
  );
}