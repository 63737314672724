
import React, { useEffect, useState } from 'react';
import './product.css';
import axios from 'axios';
import { DataGrid , GridToolbarExport } from '@mui/x-data-grid';
import { Email, Block, CenterFocusWeakOutlined, DeleteOutline, RemoveRedEye } from '@mui/icons-material';
import Chip from '@mui/material/Chip';
import { Link } from 'react-router-dom';
import Toptag from '../../components/topTag/Toptag';
import Tooltip from '@mui/material/Tooltip';
import { Search } from '@mui/icons-material/';
import Loader from '../../components/loader/Loader'
import { ToastContainer, toast } from 'react-toastify';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { NavLink} from 'react-router-dom';

import {  Button, Menu , MenuItem } from '@mui/material';



export default function Product() {

//   const [one, two] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [pageSize, setPageSize] = useState(10);
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const open = Boolean(anchorEl);
//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
// };
// const handleClose = () => {
//     setAnchorEl(null);
// };


//   const blockProduct = async (_id, isActive) => {
//     var data = { _id, isActive }
//     const blockUnblock = await axios.post('https://admin.thetapplist.com/admin/blockProduct', data);
//     getData();
//   }

//   const handleKeyDown = e => {
//     console.log(e.target.value)
//     if (e.target.value === '') {
//       if (e.key === " ") {
//         e.preventDefault();
//       }
//     }
//   };


//   const filterFunction = async (event) => {

//     let val = event.target.value;
//     console.log(val, "vaallll")
//     const ff = await axios.get('https://admin.thetapplist.com/admin/getProducts', { params: { searchtext: val } });
//     two(ff.data.data)
//   }

//   const handleDelete = async (_id) => {
//     var data = { _id }
//     // const blockUnblock = await axios.post('https://admin.thetapplist.com/admin/deleteUser', data);
//     // if(res.data.status) {
//     //   toast.success('Profile Updated !', {position: toast.POSITION.TOP_RIGHT})
//     // }else{
//     //   toast.error('Something went wrong !', {position: toast.POSITION.TOP_RIGHT
//     //   });
//     // }
//     two(one.filter((item) => item._id !== _id))
//     toast.success('Temporary Deletion !', { position: toast.POSITION.TOP_RIGHT })
//     getData();
//   }

//   const getData = async () => {
//     const ff = await axios.get('https://admin.thetapplist.com/admin/getProducts', { params: { searchtext: '' } });
//     console.log(ff.data.data, "ff")
//     two(ff.data.data);
//     if (ff) { setIsLoading(false); }

//   }

//   useEffect(() => {
//     setIsLoading(true);
//     getData();
//   }, [])




//   const columns = [
//     //  { field: 'id', headerName: 'ID', minWidth: 250 ,maxWidth: 290, flex:0.9 },
//     // { field: 'number', headerName: '#', minWidth: 100, maxWidth: 140, flex: 0.9 },

//     {
//       field: 'name', headerName: 'Name', minWidth: 280, maxWidth: 320, flex: 2.5,
//       renderCell: (params) => {
//         return (
//           <div className="userListUser">
//             <img src={params.row.images[0]} alt="" className="userListImg" />
//             {params.row.name}
//           </div>
//         )
//       }
//     },
//     {
//       field: 'quantity', headerName: 'Quantity', minWidth: 120, maxWidth: 150, flex: 3,
//       renderCell: (params) => {
//         return (
//           <div className="userListUser">

//             {params.row.quantity}
//           </div>
//         )
//       }
//     },
//     {
//       field: 'discount', headerName: 'Discount', minWidth: 120, maxWidth: 150, flex: 3,
//       renderCell: (params) => {
//         return (
//           <div className="userListUser">
//             {`${params.row.discount}%`}
//           </div>
//         )
//       }
//     },
//     {
//       field: 'offer_price',
//       headerName: 'Price',
//       description: 'This column has a value getter and is not sortable.',
//       minWidth: 100,
//       mXWidth: 130,
//       flex: 1,

//     },
//     {
//       field: 'catdetail.category_name',
//       headerName: 'Category',
//       description: 'This column has a value getter and is not sortable.',
//       minWidth: 160,
//       mXWidth: 220,
//       flex: 1,
//       renderCell: (params) => {
//         return (
//           <div className="userListUser">
//             {/* <div className="userListUser">
//             <img src={params.row.catdetail.image[0]} alt="" className="userListImg" />
//             {params.row.catdetail.category_name}
//           </div> */}
//             <Chip className={`chipSeller`} icon={<img src={params.row.catdetail.image[0]} alt="" className="userListImg" />} label={params.row.catdetail.category_name} />
//           </div>
//         )
//       }

//     },
//     // {
//     //   field: 'isActive',
//     //   headerName: 'Visible',
//     //   description: 'This column has a value getter and is not sortable.',
//     //   minWidth: 130,
//     //   mXWidth: 160,
//     //   flex: 1,
//     //   renderCell: (params) => {
//     //     return (
//     //       <div className="userListUser">
//     //         <Chip className={`chipSeller ${params.row.isActive}`}  label={params.row.isActive ? 'Visible' : 'Not Visible'} />
//     //       </div>
//     //     )
//     //   }

//     // },
//     {
//       field: 'created_at',
//       headerName: 'Added on',
//       minWidth: 100,
//       maxWidth: 230,
//       flex: 2

//     },


//     {
//       field: "action",
//       headerName: "Action",
//       minWidth: 130,
//       maxWidth: 230,

//       renderCell: (params) => {
//         return (
//           <>
//             <Link to={`/products/details`} state={{ id: params.row._id }}>
//               <Tooltip title="product details" >
//                 <RemoveRedEye className='visibilityIc' />
//               </Tooltip>
//             </Link>
//             <Tooltip title={params.row.isActive ? 'hide product' : 'unhide product'} >
//               <Block className={`block ${params.row.isActive.toString()}`} id='isActive' value={one.isActive} onClick={() => blockProduct(params.row._id, params.row.isActive)} />
//             </Tooltip>
//             <Tooltip title="Delete Product" >
//               <DeleteOutline className="userListDelete" onClick={() => handleDelete(params.row._id)} />
//             </Tooltip>
//           </>

//         )
//       }
//     }
//   ];

//   return (
//     <>
//       {isLoading
//         ?

//         <Loader />

//         :
//         <div className="container-fluid">
//           <Toptag />
//           <ToastContainer />

//           <div className="addTopB">
//             <div className='searchBar'>
//                 <input type="text" placeholder='Search... ' onKeyDown={handleKeyDown} id='searchtext' onChange={filterFunction} className='searchBarInput' />
//                 <Search className='searchIcon' />
//                 </div>
//                 {/* <Tooltip title="Add new Product" > */}
                  
//                 <div className='userAddButton2'>
               
//                 <Button
//                     id="basic-button"
//                     aria-controls={open ? 'basic-menu' : undefined}
//                     aria-haspopup="true"
//                     aria-expanded={open ? 'true' : undefined}
//                     onClick={handleClick}
//                     sx={{shadow: 'none' , padding: '0px', marginRight: '60px'}}
//                 >
//                 {/* <div className='userAddButton2'>Add</div> */}
//                 <span style={{color: 'white'}}>Add</span>
//                 </Button>
//                 <Menu
//                     id="basic-menu"
//                     anchorEl={anchorEl}
//                     open={open}
//                     onClose={handleClose}
//                     MenuListProps={{
//                         'aria-labelledby': 'basic-button',
//                     }}
//                 >
//                     <NavLink to='/products/add_CSV' className='link'>
//                         <div>
//                     <MenuItem onClick={handleClose}>Upload CSV</MenuItem>
//                         </div>
//                     </NavLink>
//                     <NavLink to='/products/add' className='link'>
//                         <div>
//                     <MenuItem onClick={handleClose}>Manual add</MenuItem>
//                         </div>
//                         </NavLink>
                  
//                 </Menu>
//                 </div>

              
//                 {/* </Tooltip> */}
//           </div>


//           <div className="mainBox">
//             <div style={{ height: 530 }}>
//               <DataGrid className='gridBox'
//                 sx={{
//                   '.MuiDataGrid-columnHeaderTitle': {
//                     fontWeight: '600',
//                     fontSize: '15px'
//                   },
//                   '.MuiDataGrid-columnHeaders': {

//                     border: 'ridge'
//                   }
//                 }}
//                 disableSelectionOnClick
//                 rows={one}
//                 columns={columns}
//                pageSize={pageSize}
//                disableColumnMenu
//             onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
//             rowsPerPageOptions={[5, 10, 20, 30 ,50, 100]}

//             components={{ Toolbar: GridToolbarExport  }}

//               />
//             </div>
//           </div>
//         </div>
//       }
//     </>
//   )
}








