import {useEffect, useState } from 'react';
import './home.css'
import { useNavigate } from "react-router-dom";
import Card from '../../components/cards/Card'




export default function Home() {
  const navigate = useNavigate();
  

  useEffect(() => {
    navigate('/home')
  }, [])
  
   
  // useEffect(() => {
  //  const exist =  sessionStorage.getItem('value');
  //  const exist1 =  localStorage.getItem('local');
  //   console.log(exist, "exists or not")
  //     const data = 1;
  //     const data1 = 2;
  //     // if(!exist) return alert('session expired')
  //     console.log(exist1, "exists222")
  //     if(exist1) return 
  //       localStorage.removeItem('local')
  //       alert('removed')
      
  //     sessionStorage.setItem('value', data)
  //     localStorage.setItem('local', data1)
  // }, [])
  
  return (
    <div className='home'>
        
        {/* <Chart data= {userData} title="User Analytics" grid dataKey="Active User"/> */}

        <Card/>
        
        </div>
  )
}
