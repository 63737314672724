
import React, { useEffect } from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "../../components/rtEditor/Toolbar";
import "react-quill/dist/quill.snow.css";
import Toptag from "../../components/topTag/Toptag";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";


export default function Disclaimer() {

  const [one, two] = React.useState();
  const [id,setId]=React.useState("")

  const handleChange = data => {
    console.log(data, 'val')
    two(data)
  };
 
  // const updateContent = (e)=> {
  //   e.preventDefault();
  //   console.log('hi')
  //   let obj = { one}
  //   axios.post('https://admin.thetapplist.com/admin/addContentPages', obj ).then((res) => {
  //       if (res.data.status) {
  //         loadList();
  //         toast.success(' Updated !', { position: toast.POSITION.TOP_RIGHT })
  //       } else {
  //         toast.error('Something went wrong !', {
  //           position: toast.POSITION.TOP_RIGHT
  //         });
  //       }
  //     });
  // }

  const updateContent = (e)=> {
    e.preventDefault();
    console.log('hi')
    let obj = { disclaimer:one, disclaimerId:id}
    console.log(obj,"the  obj")
    axios.post('https://admin.thetapplist.com/admin/update_disclaimer', obj ,{headers:{"token":sessionStorage.getItem("token")}}).then((res) => {
      console.log(res.data.status, "This is the status I have")
        if (res.data.status) {
          loadList();
          toast.success(' Updated !', { position: toast.POSITION.TOP_RIGHT })
        } else {
          toast.error('Something went wrong !', {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      });
  }


  const loadList = () => {
    axios.get('https://admin.thetapplist.com/admin/get_disclaimer',{headers:{"token":sessionStorage.getItem("token")}}).then((res) => {
      console.log(res.data.data, "disclamareee")
      if (res.data.status) {
     two(res.data.data.data)
     setId(res.data.data._id)
      }
    });
  }


  useEffect(() => {
    loadList()
  }, [])


  return (
    <div className='container-fluid'>
            <Toptag />
            <ToastContainer />
            <div className="userDetCont">
                <div className="text-editor"  >
                    <EditorToolbar  />
                    <ReactQuill
                        style={{ height: '470px' }}
                        theme="snow"
                        id='quillState'
                        value={one}
                        onChange={handleChange}
                        placeholder={"Write something..."}
                        modules={modules}
                        formats={formats}
                    />
                </div>
                <div className="contentBtn">
                  <button  className='updateDataBtn' onClick={updateContent} style={{backgroundColor: '#cacaca', color: 'black', fontWeight: '500', fontSize: '14px'}}  >
                    SUBMIT
                  </button>
              </div>
            </div>
        </div>
  );
};



