import './card.css';
import { PermIdentity, Person4, Timeline, Store } from '@mui/icons-material';
import { NavLink, useNavigate } from 'react-router-dom';
import Toptag from '../../components/topTag/Toptag';
import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import Groups2Icon from '@mui/icons-material/Groups2';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SubscriptIcon from '@mui/icons-material/Subscript';
import PolicyIcon from '@mui/icons-material/Policy';
import server from '../../server';

export default function Card() {
    const redirect= useNavigate()
    const [one, two] = useState({sellers: '', buyers: '', shops: ''});

    let formatter = Intl.NumberFormat('en', { notation: 'compact' });
    // toast.error(one)
    useEffect(() => {
        const getData = async () => {
            const ff = await axios.get(server.live_dev+'/dashboard' , {headers : {"token":sessionStorage.getItem("token")}});
            // alert(ff.data.code)
            if(ff.data.code===203){
                sessionStorage.clear()
                toast.error(ff.data.message)
                // window.location.reload(true);
                setTimeout(()=> window.location.reload(true),1000)
              }else if(ff.data.code===201){
                toast.error(ff.data.message)
              }else{
                two(ff.data.results)
              }

        }
        getData();
    },[])

    return (
        <div className="container-fluid">
            <Toptag />
            <div className="cardWrapper">
                <NavLink to='/home/user_management' className='link'>
                    <div className="cardContainer black">
                        <div className="cardTopLogo ">
                            <Person4 className='cardLogo green' />
                        </div>
                        <span className='cardTitle green'>Users</span>
                        <span className='userCount'><Timeline className='trendingLogo' />{formatter.format(one.users)}</span>
                    </div>
                </NavLink>
                <NavLink to='/home/group_management' className='link'>
                    <div className="cardContainer purple">
                        <div className="cardTopLogo">
                            <Groups2Icon className='cardLogo pink' />
                        </div>
                        <span className='cardTitle pink'>Groups</span>
                        <span className='userCount'><Timeline className='trendingLogo' />{formatter.format(one.groupCount)}</span>

                    </div>
                </NavLink>
             
                <NavLink to='/home/items' className='link'>
                    <div className="cardContainer red">
                        <div className="cardTopLogo">
                            <ShoppingCartIcon className='cardLogo red' />
                        </div>
                        <span className='cardTitle red'>Items</span>
                        <span className='userCount'><Timeline className='trendingLogo' />{formatter.format(one.items)}</span>
                    </div>
                </NavLink>

                {/* <NavLink to='/home/shop' className='link'>
                    <div className="cardContainer brown">
                        <div className="cardTopLogo">
                            <SubscriptIcon className='cardLogo brown' />
                        </div>
                        <span className='cardTitle brown'>Subscription</span>
                        <span className='userCount'><Timeline className='trendingLogo' />{formatter.format(one.shops)}</span>
                    </div>
                </NavLink> */}

            </div>
        </div>

    )
}

