import Home from './pages/home/Home';
import User from './pages/user/User';
import Profile from './pages/profile/Profile';
import ChangePass from './pages/profile/ChangePass';
import SellerDetail from './pages/seller/SellerDetail';
import BuyerDetail from './pages/buyer/BuyerDetail';
import ShopDetail from './pages/shop/ShopDetail';
import Seller from './pages/seller/Seller';
import Buyer from './pages/buyer/Buyer';
import Shop from './pages/shop/Shop';
import Userdetails from './pages/user/Userdetail';
import ProductDetail from './pages/products/ProductDetail';
import Product from './pages/products/Product';
import ProductAdd from './pages/products/ProductAdd';
import UnderConst from './pages/user/UnderConst';
import ProductAdd_CSV from './pages/products/ProductAdd_CSV';
import Categories from './pages/category/Categories';
import Privacy from './pages/content/Privacy';
import Terms from './pages/content/Terms';
import Disclaimer from './pages/content/Disclaimer';
import Profilee from './pages/user/Profilee';
import ChartData from './pages/user/ChartData';
import Chart from './components/chart/Chart';
import Maincategory from './pages/category/Maincategory';
import Login from './pages/login/Login.jsx';
import Items from './pages/items/Items';
import About from './pages/content/About';
import ProtectedRoute from './auth/auth';
import Groups from './pages/groups/Groups';
import ReportGroup from './pages/groups/ReportGroup';


// eslint-disable-next-line import/no-anonymous-default-export
export default  [
    { path: "/" ,  Component: <Home/> },
    { path: "/analytics" ,  Component: <ChartData/> },
    // { path: "/users" ,    Component: <User/> },
    { path: "/profile" ,    Component: <Profile/> },
    { path: "/change_Password" ,    Component: <ChangePass/> },
    { path: "/products" ,    Component: <Product/> },
    { path: "/products/details" ,    Component: <ProductDetail/> },
    { path: "/products/add" ,    Component: <ProductAdd/> },
    // eslint-disable-next-line react/jsx-pascal-case
    { path: "/items/add_CSV" ,    Component: <ProductAdd_CSV/> },
    { path: "/buyers" ,    Component: <Buyer/> },
    { path: "/users" ,    Component: <Seller/> },
    { path: "/home/category" ,    Component: <Categories/> },
    { path: "/home/user_management" ,  Component: <Seller/> },
    { path: "/users/details" ,   Component: <SellerDetail/> },
    { path: "/home/buyer" , Component: <Buyer/> },
    { path: "/buyers/details" ,   Component: <BuyerDetail/> },
    { path: "/home/user/details" ,   Component: <Userdetails/> },
    { path: "/home/shop" , Component: <UnderConst/> },
    { path: "/shops" , Component: <UnderConst/> },
    { path: "/shops/details" ,   Component: <ShopDetail/> },
    { path: "*" ,  Component: <Home/> },
    { path: "/orders" ,  Component: <UnderConst/> },
    { path: "/subscriptions" ,  Component: <UnderConst/> },
    { path: "/disputes" ,  Component: <UnderConst/> },
    { path: "/privacy_Policy" ,  Component: <Privacy/> },
    { path: "/home/privacy_Policy" ,  Component: <Privacy/> },
    { path: "/terms_&_Conditions" ,  Component: <Terms/> },
    { path: "/disclaimer" ,  Component: <Disclaimer/> },
    {path:'/group_management',Component:<Groups/>},
    {path:'/home/group_management',Component:<Groups/>},
    {path:'/home/reported',Component:<ReportGroup/>},
    // { path: "/login" ,  Component: <Login/> },
    { path: "/items" ,Component:<Items/>},
    { path: "/home/items" ,Component:<Items/>},
    { path: "/about_app" ,Component:<About/>}
]