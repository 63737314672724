import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import "./profile.css";
import Toptag from "../../components/topTag/Toptag";
import {
  ConnectingAirportsOutlined,
  Publish,
  TwoKPlus,
} from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import Loader from "../../components/loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import server from "../../server";
import { useNavigate } from "react-router-dom";

export default function Profile() {
  const inputFile = useRef(null);
  const redirect = useNavigate()
  const [edit, setEdit] = useState("disabled");
  const [edit1, setEdit1] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [one, two] = useState({});
  const [three, four] = useState({
    pic: "",
    localPic: "",
  });

  const onButtonClick = () => {
    console.log(inputFile, "file");
    console.log(typeof edit1, "down edit1");
    console.log(edit1 === "disabled", "down edit1");
    inputFile.current.click();
  };

  const editFunction = () => {
    setEdit("");
    setEdit1("disabled");
  };

  const handleKeyDown = (e) => {
    console.log(e.target.value);
    if (e.target.value === "") {
      if (e.key === " ") {
        e.preventDefault();
      }
    }
  };

  const inputEvent = (e) => {
    const { id, value } = e.target;
    two((prevalue) => {
      return {
        ...prevalue,
        [id]: value,
      };
    });
  };

  const handleChange = (e) => {
    console.log(e.target.files[0], "threegggggggggggggggggggggggggg");
    if (e.target.files.length > 0) {
      let filename = e.target.files[0];

      four({
        localPic: URL.createObjectURL(filename),
        pic: filename.name,
        file: filename,
      });
    }
  };

  const updateAdminProfile = (event) => {
    console.log(
      three.file,
      "threeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
    );
    event.preventDefault();
    const formData = new FormData();
    formData.append("file", three.file);
    formData.append("name", one.name);
    // console.log(formData , "GGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGG")
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        token: sessionStorage.getItem("token"),
      },
    };

    let obj = {};

    axios
      .post(server.live_dev+"/update_profile", formData, {
        headers: { token: sessionStorage.getItem("token") },
      })
      .then((res) => {
        console.log(res, "1000000000000000000000000000000000000000000");
        if(res.data.code===203){
          toast.error(res.data.message)
          sessionStorage.clear()
          setTimeout(()=> window.location.reload(true),1000)
        }else if(res.data.code===201){
          toast.error(res.data.message)
        }else{
          toast.success("Profile Updated !", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setEdit("disabled");
          setEdit1("");
          getData();
      }
      });
   
  };

  const getData = async () => {
    const ff = await axios.get(server.live_dev+"/admin_profile", {
      headers: { token: sessionStorage.getItem("token") },
    });
    console.log(
      ff.data.results.profile_pic,
      "111111111111111111111111111111111111111111111111111111111111"
    );
    if(ff.data.code===203){
      toast.error(ff.data.message)
      sessionStorage.clear()
      setTimeout(()=> window.location.reload(true),1000)
    }else if(ff.data.code===201){
      toast.error(ff.data.message)
    }else{
    two(ff.data.results);
    sessionStorage.removeItem("profile_pic");
    sessionStorage.setItem("profile_pic", ff.data.results.profile_pic);
    four({ localPic: ff.data.results.profile_pic });
    if (ff) {
      setIsLoading(false);
    }
  }
  };

  useEffect(() => {
    setIsLoading(true);
    getData();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="container-fluid">
          <Toptag />
          <ToastContainer />

          <div className="userDetCont">
            <div className="userTitleContainer">
              <span className="detailTitle">User Profile</span>
              <button
                className="userAddButton"
                hidden={edit1}
                onClick={editFunction}
              >
                Edit
              </button>
            </div>

            <div className="mainBoxx">
              <div className="imGCont">
                <Tooltip
                  title={edit1 === "disabled" ? "Click to upload Pic" : ""}
                >
                  <img
                    src={three.localPic}
                    name="profile_pic"
                    onClick={onButtonClick}
                    className="imgCont1"
                    alt=""
                  />
                </Tooltip>
                <input
                  type="file"
                  ref={inputFile}
                  hidden
                  disabled={edit1 === "disabled" ? "" : "disabled"}
                  accept="image/*"
                  className="form-control"
                  onChange={handleChange}
                />
              </div>

              <div className="formContainer">
                <div className="formInMain">
                  <label className="myLabel">Name</label>
                  <input
                    type="text"
                    disabled={edit}
                    onKeyDown={handleKeyDown}
                    id="name"
                    value={one.name}
                    onChange={inputEvent}
                    className="myInput"
                  />

                  <label className="myLabel">Email</label>
                  <input
                    type="text"
                    readOnly
                    disabled
                    id="email"
                    onKeyDown={handleKeyDown}
                    value={one.email}
                    onChange={inputEvent}
                    className="myInput"
                  />
                </div>
              </div>
            </div>
            <div className="SubmitBtnBox">
              <Tooltip title="Click to Update profile">
                <button
                  hidden={edit}
                  className="updateDataBtn"
                  onClick={updateAdminProfile}
                >
                  Update
                </button>
              </Tooltip>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
