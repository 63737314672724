import React, { useState } from "react";
import "../../pages/category/categories.css";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import server from "../../server";
import { useNavigate } from "react-router-dom";

function Addcategory({ isDeleteOpened, onClick, CloseDialog, body }) {
  const history = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [category, setCategory] = useState("");
  console.log(category, "this is that");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleClose = () => {
    //setOpen(false);
    CloseDialog(false);
  };

  const addNew = async () => {
    if (category.trim().length <= 0)
      return toast.error("White spaces are not allowed");
    // toast.error("ddd")
    await axios
      .post(
        server.live_dev + "/create_category",
        { category: category },
        { headers: { token: sessionStorage.getItem("token") } }
      )
      .then((res) => {
        if (res.data.code === 203) {
          toast.error(res.data.message);
          sessionStorage.clear();
          setTimeout(() => history("/"), 1000);
        } else if (res.data.code === 201) {
          toast.error(res.data.message);
        } else {
          CloseDialog(false);
          toast.success(res.data.message);
          onClick();
        }
      });
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={isDeleteOpened}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Would you like to create a new category"}
        </DialogTitle>
        <DialogContent>
          <TextField
            id="outlined-basic"
            label="New Category"
            variant="outlined"
            onChange={(event) => setCategory(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={addNew} autoFocus>
            Add New
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Addcategory;
