

import React, { useEffect, useRef, useState } from 'react';
import './productAdd.css'
import Tooltip from '@mui/material/Tooltip';
import { ToastContainer, toast } from 'react-toastify';
import Toptag from '../../components/topTag/Toptag';
import { CarRepairRounded, DeleteForever, Upload } from '@mui/icons-material/';
import axios from 'axios';

export default function ProductAdd() {
  const [file, setFile] = useState([]);
  const [file1, setFile1] = useState([]);
  const [v, setV] = useState('')
  const [one, two] = useState([])
  const [three, four] = useState({
    name: '',
    price: '',
    category: '',
    veg: '',
    offer_price: '',
    Brand_name: '',
    quantity: '',
  })
  const inputRef = useRef(null);

  const uploadSingleFile = (e) => {
    setFile([...file, URL.createObjectURL(e.target.files[0])]);
    setFile1([...file1, e.target.files[0]]);

  }

  const handleClick = () => {

    inputRef.current.click();
  }


  const inputEvent = (e) => {
    const { id, value } = e.target;
    four((prevalue) => {
      return {
        ...prevalue,
        [id]: value
      };
    });

    if (e.target.id === 'category') {
      let catArr = ['6321bbb1f312178ff1f3605a', '6321bbeaf312178ff1f36060', '6321bbf6f312178ff1f36066']
      const xx = catArr.filter((item) => {
        if (item === e.target.value) { return true }
      })
      if (xx.length > 0) { setV('') } else { setV('disabled') }
    }

  };

  const handleKeyDown = e => {

    if (e.target.value === '') {
      if (e.key === " ") {
        e.preventDefault();
      }
    }
  };

  const deleteFile = (e) => {
    const s = file.filter((item, index) => index !== e);
    setFile(s);

  }

  const addProduct = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    console.log(three.category, 'cat')
    file1.map((elem, index) => {
      formData.append('prodImg', elem)
    })
    formData.append("name", three.name);
    formData.append("category", three.category);
    formData.append("price", three.price);
    
    if(v === ''){console.log('aa yga ') ; }
    formData.append("offer_price", three.offer_price);
    formData.append("Brand_name", three.Brand_name);
    formData.append("quantity", three.quantity);
    formData.append("description", three.description);

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    let obj = {}
    axios.post('https://admin.thetapplist.com/admin/addProduct', formData, obj, config).then((res) => {
      if (res.data.status) {
        toast.success('Product Added !', { position: toast.POSITION.TOP_RIGHT })
      } else {
        toast.error('Something went wrong !', {
          position: toast.POSITION.TOP_RIGHT
        });

      }
    });
  }



  const getData = async () => {
    const ff = await axios.get('https://admin.thetapplist.com/admin/categoryList');

    two(ff.data.data);

  }


  useEffect(() => {
    getData();
  }, []);

  return (
    <div className='container-fluid'>
      <Toptag />
      <ToastContainer />
      <div className="mainWrapper">
        <div className="userDetCont">
          <div className="userTitleContainer">
            <span className="detailTitle">Add Product</span>

          </div>

          <form className="userUpdateForm" onSubmit={addProduct}>
            <span className='detailTitle2'>Product Images</span>
            <div className="imgContMultiple">
              <div>

              
              <div className="selectFileCont" onClick={handleClick}>
                <input
                  type="file"
                  ref={inputRef}
                  hidden
                  disabled={file.length === 5}
                  accept="image/*"
                  className="form-control"
                  onChange={uploadSingleFile}
                />
                <img className='selectImage' src="/images/addtocart.png" alt="" />
                <div className='spandiv'>

                  <Upload />  <span className='addproSpan'>Add product Images</span>
                </div>
              </div>
              </div>

              <div  className='imgSelectedBigCont'>
                {file.length > 0 &&
                  file.map((item, index) => {
                    return (
                      <div className='imgSelectedSm' key={item}>
                        <img src={item} className='smallImg' alt="" />
                        <DeleteForever className='deleteIcon' sx={{ color: 'red' }} onClick={() => deleteFile(index)} />
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="infoContainer">

              <div className="spanContainer">
                <span className="userShowTitle">Product Details</span>
              </div>

              <div className="infoTable">
                <label className='labelCont'>Name</label>
                <input type="text" id='name' value={three.name} required={true} onKeyDown={handleKeyDown} onChange={inputEvent} className="userInput" />
              </div>

              <div className="infoTable">
                <label className='labelCont'>Category</label>
                {/* <input type="list" id='category_name' value='123' className="userInput" /> */}

                <select className="userInput" required={true} onChange={inputEvent} id='category'  >
                  {one.length > 0 &&
                    one.map((item, index) => {
                      return (
                        <option id='category' key={index} value={item._id}>{item.category_name}</option>
                      );
                    })}

                </select>

              </div>
              <div className="infoTable" id='veg'   onChange={inputEvent} style={{ flexDirection: 'column' }}>
                <div  style={{ color: 'rgb(41, 40, 40)', padding: '5px' }} > <input type="radio" style={{cursor: 'pointer'}}  disabled={v} defaultChecked id='veg1' value={true} name="veg" />   <label htmlFor='veg1' style={{cursor: 'pointer'}} > Veg </label>  </div>
                <div style={{ color: 'rgb(41, 40, 40)', padding: '5px' , cursor: 'pointer'  }} ><input type="radio"  style={{cursor: 'pointer'}} disabled={v} id='veg2' value={false} name="veg" />   <label htmlFor='veg2' style={{cursor: 'pointer'}} > Non Veg </label> </div>


              </div>
              <div className="infoTable">
                <label className='labelCont'>Price</label>
                <input type="number" min={1} id='price'  value={three.price > 0 ? three.price : three.price = ''} required={true} onKeyDown={handleKeyDown} onChange={inputEvent} className="userInput" />
              </div>
              <div className="infoTable">
                <label className='labelCont'>Offer Price</label>
                <input type="number" min='1' id='offer_price'  value={three.offer_price <= three.price ? three.offer_price : three.offer_price = ''} required={true} onKeyDown={handleKeyDown} onChange={inputEvent} className="userInput" />
              </div>
              <div className="infoTable">
                <label className='labelCont'>Brand Name</label>
                <input type="text" id='Brand_name' value={three.Brand_name} required={true} onKeyDown={handleKeyDown} onChange={inputEvent} className="userInput" />
              </div>
              <div className="infoTable">
                <label className='labelCont'>Quantity</label>
                <input type="number" id='quantity' min='1' value={three.quantity > 0 ? three.quantity : three.quantity = ''} required={true} onKeyDown={handleKeyDown} onChange={inputEvent} className="userInput" />
              </div>
              <div className="infoTable">
                <label className='labelCont'>Description</label>
                {/* <input type="text"   id='category_name'  value='123' className="userInput" /> */}
                <textarea className="userInput1" id='description' cols="20" rows="4" onKeyDown={handleKeyDown} required={true} value={three.description} onChange={inputEvent} />
              </div>

              <div className="infoTable">
                <Tooltip title="Click to Add Product" >
                  <button className='updateBut'  >
                    ADD
                  </button>
                </Tooltip>
              </div>

            </div>
          </form>
        </div>
      </div>
    </div>

  )
}

