import * as React from "react";
import "./items.css";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
// import Toptag from "../../components/topTag/Toptag";
import Tooltip from "@mui/material/Tooltip";
import {
  Add,
  Block,
  DeleteOutline,
  Email,
  RemoveRedEye,
  Search,
  Download,
} from "@mui/icons-material";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import Loader from "../../components/loader/Loader";
import { Avatar, Button } from "@mui/material";
import UserBlock from "../../components/modal/UserBlock";
import DeleteUser from "../../components/modal/DeleteUser";
import moment from "moment/moment";
import server from "../../server";
import Toptag from "../../components/topTag/Toptag";
import Addcategory from "../../components/modal/Addcategory";
import Deletecategory from "../../components/modal/Deletecategory";
import Additem from "../../components/modal/Additem";
import Deleteitem from "../../components/modal/Deleteitem";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import file from "../../static/file.csv";

const columns = [
  // { id: "s/n", label: "", align: "right", minWidth: 12 },
  { id: "image", label: "", align: "right", minWidth: 12 },
  { id: "item", label: "Item", minWidth: 170 },
  { id: "category", label: "category", minWidth: 170 },
  // {
  //   id: "email",
  //   label: "email",
  //   minWidth: 170,
  //   // align: 'right',
  // },
  {
    id: "price",
    label: "Price",
    minWidth: 70,
    align: "left",
  },
  {
    id: "Created",
    label: "Date created",
    minWidth: 70,
    align: "left",
  },
  // {
  //   id: "isEmailVerified",
  //   label: "Email Verified",
  //   minWidth: 170,
  //   align: "center",
  // },
  {
    id: "actions",
    label: "Actions",
    minWidth: 170,
    align: "right",
  },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const exampleData = [
  ["Item", "Category", "Price", "Images"],
  [
    "BRITANNIA Gold Marie Biscuit",
    "Groceries",
    "10",
    "https://rukminim1.flixcart.com/image/416/416/xif0q/cookie-biscuit/h/d/b/-original-imaghxgjurakjhue.jpeg?q=70",
  ],
  [
    "Sunfeast Dark Fantasy Choco Fills Cream Filled",
    "Groceries",
    "10",
    "https://rukminim1.flixcart.com/image/416/416/l0tweq80/cookie-biscuit/6/d/v/-original-imagcj64ufcuhukb.jpeg?q=70",
  ],
  [
    "Umadi's Mixed Vegetable Pickle",
    "Vegetables",
    "70",
    "https://rukminim1.flixcart.com/image/416/416/kqfj1jk0/pickle-murabba/h/d/0/1-mixed-veg-pickle-pouch-pickle-umadis-original-imag4fzzyyhz2xcp.jpeg?q=70",
  ],
];

const downloadExampleCSV = () => {
  const link = document.createElement("a");
  link.href = file;
  link.download = "example.csv";
  link.click();
};

export default function Items() {
  const redirect = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [user_data, setUsersData] = useState([]);
  const [total_docs, setTotal] = useState(0);
  const [create_view, setCreate] = useState(false);
  const [delete_view, setDelete] = useState(false);
  const [b_userId, setUserId] = useState("");
  const [d_userId, setDlUserId] = useState("");
  const [block_status, setblockStatus] = useState();
  const [search, setSearch] = useState("");
  const [f_total, setFtotal] = useState(0);
  const [category, setCategory] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    getData();
  };
  let sn = 1;

  useEffect(() => {
    getData(page, rowsPerPage);
  }, [page, rowsPerPage]);

  const getData = async () => {
    await axios
      .get(server.live_dev + "/category_for_dropdown", {
        headers: { token: sessionStorage.getItem("token") },
      })
      .then((res) => {
        if (res.data.code === 200) {
          setCategory(res.data.data);
        }
      });
    await axios
      .get(server.live_dev + "/get-items", {
        headers: { token: sessionStorage.getItem("token") },
        params: { skip: page, limit: rowsPerPage, search: search },
      })
      .then((res) => {
        setUsersData(res.data.data);
        if (res.data.data.length > 0) {
          setIsLoading(true);
          setTotal(res.data.totalDocuments);
          setFtotal(res.data.totalDocuments);
        }
      });
  };
  console.log(user_data, "fffff");
  const hanldeBlock = (id, status) => {
    console.log(status, "fffff");
    setUserId(id);
    setCreate(!create_view);
    setblockStatus(status);
  };

  const hanldeDelete = (id) => {
    setDlUserId(id);
    setDelete(!delete_view);
    getData();
  };
  const searchdata = async (e) => {
    // setSearch(e.target.value)
    console.log(e.target.value, "the value");
    await axios
      .get(server.live_dev + "/get-items", {
        headers: { token: sessionStorage.getItem("token") },
        params: {
          skip: e.target.value === "" ? page : "0",
          limit: e.target.value === "" ? rowsPerPage : f_total,
          search: e.target.value,
        },
      })
      .then((res) => {
        if (res.data.code === 203) {
          toast.error(res.data.message);
          sessionStorage.clear();
          setTimeout(() => window.location.reload(true), 1000);
        } else if (res.data.code === 201) {
          toast.error(res.data.message);
        } else {
          setUsersData(res.data.data);
          if (res.data.data.length > 0) {
            setIsLoading(true);
            setTotal(res.data.totalDocuments);
          }
        }
      });
  };

  const toCsv = () => {
    redirect("/items/add_CSV");
  };

  return (
    <>
      <Toptag />
      <ToastContainer />
      {isLoading === false ? (
        <Loader />
      ) : (
        <div>
          <div className="container-fluid">
            <div className="information-space">
              <ul>
                <li>Files must be in csv format</li>
                <li>
                  The file should contain the field names "Item", "Category",
                  "Price", and "Images". Please note that these fields are case
                  sensitive.
                </li>
                <li>
                  The items in the file should be unique and not repetitive.
                </li>
                <li>
                  Only items related to the existing categories will be added to
                  the list.
                </li>
                <li>
                  You can download the sample format by clicking on the button
                  below.
                </li>
                <Button
                  className="download-button"
                  variant="contained"
                  startIcon={<Download />}
                  onClick={downloadExampleCSV}
                />
              </ul>
            </div>
            <div
              style={{
                marginBottom: "30px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <div className="searchBarcategorys">
                  <input
                    type="text"
                    placeholder="Search... "
                    // onKeyDown={handleKeyDown}
                    // id="searchtext"
                    onChange={(event) => searchdata(event)}
                    className="searchBarInputcate"
                  />
                  <Search className="searchIconcate" />
                </div>
              </div>
              <div>
                <button
                  className="addbtn"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  Add New Item
                </button>
              </div>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  className="buttons"
                  onClick={() => setCreate(!create_view)}
                >
                  Create manually
                </MenuItem>
                <MenuItem className="buttons" onClick={toCsv}>
                  Create using csv
                </MenuItem>
              </Menu>
              {/* onClick={()=>setCreate(!create_view) */}
            </div>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 530 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {user_data.map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          {/* <TableCell align="center">
                          {sn++}
                        </TableCell> */}
                          <TableCell>
                            <Avatar
                              alt="Remy Sharp"
                              src={row.itemImage}
                              // src={row.profile_photo ==="" ? server.user_static: server.live_image+row.profile_photo}
                            />
                          </TableCell>
                          {/* https://th.bing.com/th/id/OIP.7c6wkFQ9XQ2jIjGYZZALrwHaE7?pid=ImgDet&rs=1 */}

                          <TableCell>{row.item}</TableCell>
                          <TableCell>{row.category}</TableCell>
                          <TableCell>{row.price}</TableCell>
                          {/* <TableCell>{row.email}</TableCell> */}
                          <TableCell>
                            {moment
                              .unix(row.createdAt / 1000)
                              .format("MM/DD/YYYY")}
                          </TableCell>

                          <TableCell align="right">
                            {/* <Tooltip title="view profile">
                            <RemoveRedEye className="visibilityIc" />
                          </Tooltip> */}
                            {/* <Link
                            to={`/sellers/details`}
                            state={{ id: row._id }}
                          >
                            <Tooltip title="view profile">
                              <RemoveRedEye className="visibilityIc" />
                            </Tooltip>
                          </Link> */}
                            {/* <Tooltip
                            title={
                              row.adminBlock === false
                                ? "block user"
                                : "unblock user"
                            }
                          >
                            <Block
                              className={`block ${"block"}`}
                              id="isActive"
                              value={row.adminBlock}
                              onClick={() => hanldeBlock(row._id,row.adminBlock)}
                            />
                          </Tooltip> */}
                            <Tooltip title="Delete Category">
                              <DeleteOutline
                                className="userListDelete"
                                onClick={() => hanldeDelete(row._id)}
                              />
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={total_docs}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </div>

          <Additem
            isDeleteOpened={create_view}
            body={category}
            onClick={getData}
            CloseDialog={() => setCreate(false)}
          />

          <Deleteitem
            isDeleteOpened={delete_view}
            body={d_userId}
            onClick={getData}
            CloseDialog={() => setDelete(false)}
          />

          {/* {
            block_view === true ? (
                <UserBlock props={block_view}/>
            ) : <UserBlock props={block_view}/>
        } */}
        </div>
      )}
    </>
  );
}
