import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { TextField, useMediaQuery, useTheme } from '@mui/material';
import { toast } from 'react-toastify';
import axios from 'axios';
import server from '../../server';
import MembersList from './MembersList';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function Members({ group,isDeleteOpened,onClick, CloseDialog ,body }) {
    const history =useNavigate()
    const [category,setCategory]= useState('')

    console.log(group,"dddddd",body)
  // const [open, setOpen] = React.useState(props === true ? true :false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  console.log(body,"fffff")
  // const handleClickOpen = () => {
  //   setOpen(props);
  // };

  const handleClose = () => {
    //setOpen(false);
    CloseDialog(false);
  };

  // const delete_submit = (id) =>{
  //   axios.post(server.live_dev + "/delete_category",{categoryId:id},{headers:{"token":sessionStorage.getItem("token")}}).then((res)=>{
  //       if(res.data.code===203){
  //           toast.error(res.data.message) 
  //           sessionStorage.clear()
  //           setTimeout(()=> window.location.reload(true),1000)
  //       } else if(res.data.code===201){
  //           toast.error(res.data.message)
  //       }else{
  //           handleClose()
  //           onClick()
  //           toast.success(res.data.message)
  //       }
  //   })
  // } 

  

  // useEffect(()=>{
  //   props ===  true ? setOpen(true) : setOpen(false)
  // },[props])

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={isDeleteOpened}
        onClose={handleClose}
        fullWidth
        aria-labelledby="responsive-dialog-title"   
      >
        <DialogTitle id="responsive-dialog-title">
          {`Members of the group ${group}` }
        </DialogTitle>
        <DialogContent>
          <MembersList members={body}/>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
